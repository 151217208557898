<template>
  <div class="flex cad-item-box">
    <el-button @click="onShowSjk">设计库</el-button>
    <el-button
      @click="onShowMenu"
      v-if="getPackageSimpleByVal('feaProductQuery')"
      >产品样本</el-button
    >
    <el-button
      @click="showSafety"
      v-if="getPackageSimpleByVal('feaStandardQuery')"
      >行业规范</el-button
    >
    <!-- v-if="
        packData &&
          packData.packageSimple &&
          packData.packageSimple.feaExpertAnswer == 0
      " -->
    <el-button @click="onShowDy" v-if="getPackageSimpleByVal('feaExpertAnswer')"
      >专家答疑</el-button
    >
    <el-button v-if="shareUrl" @click="showShare = true">我要分享</el-button>
    <el-dialog
      class="radius-box"
      :append-to-body="true"
      :close-on-click-modal="false"
      :visible.sync="showLibrary"
      :modal="true"
      :modal-append-to-body="false"
      title="设计库"
      width="685px"
      style="text-align:left"
      v-dialogDrag
    >
      <div class="cad-dialog-wrapper">
        <div class="flex cad-dialog-search">
          <el-input
            placeholder="请输入设计库名称"
            v-model="libraryName"
            @keyup.enter.native="searchLibrary"
          >
            <i slot="prefix" class="el-input__icon el-icon-search"></i>
          </el-input>
          <el-button class="commom-button" type="primary" @click="searchLibrary"
            >搜索</el-button
          >
          <el-button class="commom-button" type="primary" @click="onShowLibrary"
            >新增</el-button
          >
        </div>
        <div class="scroll-Y cad-dialog-container">
          <div class="flex cad-dialog-item-box">
            <div v-if="loadingLibrary && libraryList.length > 0">
              <div
                class="cad-dialog-box"
                v-for="(it, index) in libraryList"
                :key="index"
              >
                <div class="cad-dialog-title">{{ it.typeName || "" }}</div>
                <div
                  class="flex cad-dialog-item-box"
                  v-if="it.dataList && it.dataList.length > 0"
                >
                  <template>
                    <div
                      class="flex-center cad-dialog-item"
                      v-for="(item, index) in it.dataList"
                      :key="index"
                      @click="onViewLibry(item, index)"
                    >
                      <div
                        class="cad-dialog-close"
                        @click.stop="delLibrary(item, index)"
                        v-if="item.canDel"
                      >
                        <img src="~images/home/icon_close.png" alt="" />
                      </div>
                      <div class="cad-dialog-top">
                        <img :src="getImgUrl(item.cover)" alt="" />
                      </div>
                      <div class="text_hidden cad-dialog-name">
                        {{ item.name }}
                      </div>
                    </div>
                  </template>
                </div>
                <div class="cad-dialog-item-box" v-else>
                  <div class="noData">
                    暂无数据
                  </div>
                </div>
              </div>
            </div>

            <div class="flex cad-dialog-item-box" v-else>
              <div class="noData">
                {{ !loadingLibrary ? "加载中..." : "暂无数据" }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <el-dialog
        class="radius-box"
        width="30%"
        title="新增设计库"
        :visible.sync="innerVisible"
        :close-on-click-modal="false"
        append-to-body
        v-dialogDrag
        @close="closeDialog"
      >
        <div style="text-align:center" class="form-wrapper" v-closeSelect>
          <el-form
            ref="libraryForm"
            :model="problemData"
            :rules="libraryRules"
            size="small"
            label-width="auto"
          >
            <el-form-item prop="typeDictId" label="类型">
              <el-select
                v-model="problemData.typeDictId"
                style="margin-right: 10px;margin-bottom: 0px;width:100%;"
                collapse-tags
                ref="selectSjk"
                placeholder="请选择类型"
              >
                <el-option
                  v-for="item in typeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="文件" prop="snapshot">
              <!-- <updateFile v-model="problemData.fileList"></updateFile> -->
              <el-upload
                ref="uploadmap"
                :auto-upload="false"
                :show-file-list="false"
                :on-change="changeLibrary"
                class="avatar-uploader"
                action=""
                accept=".dwg"
              >
                <img
                  v-if="problemData.snapshot"
                  :src="problemData.snapshot"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon addImg" />
              </el-upload>
            </el-form-item>
            <el-form-item label="设计库名称" prop="name">
              <el-input v-model="problemData.name" class="form-input" />
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="onDoSubmit" type="primary" size="nomal"
              >确定</el-button
            >
            <el-button
              @click="cancelDialog('innerVisible')"
              size="nomal"
              native-type="button"
              >关闭</el-button
            >
          </div>
        </div>
      </el-dialog>
    </el-dialog>
    <el-dialog
      class="radius-box"
      :append-to-body="true"
      :visible.sync="showManu"
      :modal="true"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      title="产品样本"
      width="944px"
      v-dialogDrag
      style="text-align:left"
    >
      <div class="cad-dialog-wrapper">
        <div class="flex cad-dialog-search">
          <el-input
            placeholder="请输入厂商名称或类型"
            v-model="manuName"
            @keyup.enter.native="searchManu"
          >
            <i slot="prefix" class="el-input__icon el-icon-search"></i>
          </el-input>
          <el-button class="commom-button" type="primary" @click="searchManu"
            >搜索</el-button
          >
        </div>
        <div class="scroll-Y cad-dialog-container">
          <div v-if="loadingManu && manuList.length > 0">
            <div
              class="cad-dialog-box"
              v-for="(it, index) in manuList"
              :key="index"
            >
              <div class="cad-dialog-title">{{ it.typeName || "" }}</div>
              <div
                class="flex cad-dialog-item-box"
                v-if="it.dataList && it.dataList.length > 0"
              >
                <template>
                  <div
                    class="flex-center cad-manu-item"
                    v-for="(item, index) in it.dataList"
                    :key="index"
                  >
                    <div
                      class="cad-manu-down"
                      @click="downManuData(item)"
                      v-if="item.visible == 0"
                    >
                      详细资料
                    </div>
                    <div class="manu-item-info">
                      <div class="manu-title">{{ item.name }}</div>
                      <div class="manu-contact">
                        <template v-if="item.visible == 0"
                          >联系人：{{ item.contact }}</template
                        >
                      </div>
                      <div class="manu-contact">
                        <template v-if="item.visible == 0">
                          联系方式：{{ item.phone }}</template
                        >
                      </div>
                    </div>
                    <div class="flex-center manu-item-img">
                      <img v-if="item.cover" :src="item.cover" alt="" />
                      <div v-else>暂无图片</div>
                    </div>
                    <div class="manu-item-desc">
                      <el-tooltip
                        v-if="item.visible == 0"
                        class="item"
                        effect="dark"
                        :content="item.remark"
                        placement="top"
                      >
                        <div class="text_hidden_2 manu-hidden">
                          {{ item.remark }}
                        </div>
                      </el-tooltip>
                    </div>
                  </div>
                </template>
              </div>
              <div class="cad-dialog-item-box" v-else>
                <div class="noData">
                  暂无数据
                </div>
              </div>
            </div>
          </div>

          <div class="flex cad-dialog-item-box" v-else>
            <div class="noData">
              {{ !loadingManu ? "加载中..." : "暂无数据" }}
            </div>
          </div>
        </div>
      </div>
      <el-dialog
        class="radius-box"
        width="70%"
        :title="viewManuData.name"
        :visible.sync="onViewManu"
        :close-on-click-modal="false"
        append-to-body
        :fullscreen="fullscreen"
        v-dialogDrag
      >
        <div class="full-box" @click="fullscreen = !fullscreen">
          <svg
            t="1680921595021"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="1669"
            width="19"
            height="19"
            v-if="!fullscreen"
          >
            <path
              d="M853.333333 0h-682.666666C75.093333 0 0 75.093333 0 170.666667v682.666666C0 948.906667 75.093333 1024 170.666667 1024h682.666666c95.573333 0 170.666667-75.093333 170.666667-170.666667v-682.666666C1024 75.093333 948.906667 0 853.333333 0zM955.733333 853.333333c0 54.613333-47.786667 102.4-102.4 102.4h-682.666666c-54.613333 0-102.4-47.786667-102.4-102.4v-682.666666C68.266667 116.053333 116.053333 68.266667 170.666667 68.266667h682.666666c54.613333 0 102.4 47.786667 102.4 102.4v682.666666z"
              p-id="1670"
            ></path>
            <path
              d="M402.773333 573.44L204.8 771.413333V648.533333c0-20.48-13.653333-34.133333-34.133333-34.133333s-34.133333 13.653333-34.133334 34.133333v204.8c0 20.48 13.653333 34.133333 34.133334 34.133334h204.8c20.48 0 34.133333-13.653333 34.133333-34.133334s-13.653333-34.133333-34.133333-34.133333H252.586667l197.973333-197.973333c13.653333-13.653333 13.653333-34.133333 0-47.786667-13.653333-13.653333-34.133333-13.653333-47.786667 0zM853.333333 136.533333h-204.8c-20.48 0-34.133333 13.653333-34.133333 34.133334s13.653333 34.133333 34.133333 34.133333h122.88L573.44 402.773333c-13.653333 13.653333-13.653333 34.133333 0 47.786667 13.653333 13.653333 34.133333 13.653333 47.786667 0L819.2 252.586667v122.88c0 20.48 13.653333 34.133333 34.133333 34.133333s34.133333-13.653333 34.133334-34.133333v-204.8c0-20.48-13.653333-34.133333-34.133334-34.133334z"
              p-id="1671"
            ></path>
          </svg>
          <svg
            v-else
            t="1680921636067"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="2302"
            width="19"
            height="19"
          >
            <path
              d="M853.333333 0h-682.666666C75.093333 0 0 75.093333 0 170.666667v682.666666C0 948.906667 75.093333 1024 170.666667 1024h682.666666c95.573333 0 170.666667-75.093333 170.666667-170.666667v-682.666666C1024 75.093333 948.906667 0 853.333333 0zM955.733333 853.333333c0 54.613333-47.786667 102.4-102.4 102.4h-682.666666c-54.613333 0-102.4-47.786667-102.4-102.4v-682.666666C68.266667 116.053333 116.053333 68.266667 170.666667 68.266667h682.666666c54.613333 0 102.4 47.786667 102.4 102.4v682.666666z"
              p-id="2303"
            ></path>
            <path
              d="M812.373333 163.84L614.4 361.813333V238.933333c0-20.48-13.653333-34.133333-34.133333-34.133333s-34.133333 13.653333-34.133334 34.133333v204.8c0 20.48 13.653333 34.133333 34.133334 34.133334h204.8c20.48 0 34.133333-13.653333 34.133333-34.133334s-13.653333-34.133333-34.133333-34.133333H662.186667l197.973333-197.973333c13.653333-13.653333 13.653333-34.133333 0-47.786667-13.653333-13.653333-34.133333-13.653333-47.786667 0zM443.733333 546.133333h-204.8c-20.48 0-34.133333 13.653333-34.133333 34.133334s13.653333 34.133333 34.133333 34.133333h122.88l-197.973333 197.973333c-13.653333 13.653333-13.653333 34.133333 0 47.786667 13.653333 13.653333 34.133333 13.653333 47.786667 0L409.6 662.186667v122.88c0 20.48 13.653333 34.133333 34.133333 34.133333s34.133333-13.653333 34.133334-34.133333v-204.8c0-20.48-13.653333-34.133333-34.133334-34.133334z"
              p-id="2304"
            ></path>
          </svg>
        </div>
        <div
          class="view-manu-box"
          :class="{ 'view-full': fullscreen }"
          v-loading="loadContent"
          element-loading-text="数据加载中"
          element-loading-spinner="el-icon-loading"
          :style="{
            'overflow-y': viewManuData.fileType == 'pdf' ? 'hidden' : 'auto'
          }"
        >
          <template v-if="!loadContent">
            <!-- <iframe
              v-if="viewManuData.fileType == 'pdf'"
              :src="viewManuData.content + '?page=hsn#toolbar=0'"
              type="application/x-google-chrome-pdf"
            /> -->
            <template v-if="viewManuData.fileType == 'pdf'">
              <!-- <pdf-preview :url="viewManuData.content"></pdf-preview> -->
              <iframe
                :src="
                  'https://dendralink.com/pdf/#/?url=' + viewManuData.content
                "
              />
            </template>

            <div
              style="height:100%;"
              v-else
              v-html="viewManuData.content"
            ></div>
          </template>
        </div>
        <!-- <div slot="footer" class="dialog-footer">
          <el-button
            @click.stop="onViewManu = false"
            size="nomal"
            native-type="button"
            >关闭</el-button
          >
        </div> -->
      </el-dialog>
    </el-dialog>
    <el-dialog
      :append-to-body="true"
      :close-on-click-modal="false"
      :visible.sync="showDialog"
      :modal="true"
      :modal-append-to-body="false"
      title="专家答疑"
      width="600px"
      v-dialogDrag
      class="radius-box zdyy-dialog"
      style="text-align:left"
    >
      <div class="form-wrapper">
        <el-form
          ref="form"
          :model="dialogData"
          :rules="rules"
          size="small"
          label-width="auto"
        >
          <!-- <el-form-item label="联系人" prop="contact">
            <el-input v-model="dialogData.contact" class="form-input" />
          </el-form-item>-->
          <el-form-item label="主题" prop="title">
            <el-input v-model="dialogData.title" class="form-input" />
          </el-form-item>
          <el-form-item label="内容" prop="content">
            <el-input
              v-model="dialogData.content"
              placeholder="请输入内容"
              type="textarea"
              :rows="8"
              resize="none"
            ></el-input>
          </el-form-item>

          <el-form-item label="附件">
            <el-upload
              ref="uploadmap"
              :auto-upload="false"
              :show-file-list="false"
              :on-change="handleMapChanges"
              class="avatar-uploader zjdyfj"
              :class="{ 'zjdyfj-uploader': dialogData.snapshot }"
              action=""
            >
              <img
                v-if="dialogData.snapshot"
                :src="dialogData.snapshot"
                class="avatar"
              />
              <!-- <i v-else class="el-icon-plus avatar-uploader-icon addImg" /> -->
              <el-button v-else>添加附件</el-button>
            </el-upload>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button
          @click="cancelDialog('showDialog')"
          size="nomal"
          native-type="button"
          >取消</el-button
        >
        <el-button
          @click="doSubmit"
          type="primary"
          size="nomal"
          :loading="loadingBtn"
          >确定</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      :append-to-body="true"
      :visible.sync="showShare"
      :close-on-click-modal="false"
      :modal="true"
      :modal-append-to-body="false"
      title="我要分享"
      width="480px"
      v-dialogDrag
      style="text-align:left"
    >
      <div class="flex-center share-contnet">
        <div>
          分享地址：
        </div>
        <div style="flex:1;margin:0 8px;line-height:1.5;">{{ shareUrl }}</div>
        <span v-copy="shareUrl">复制链接</span>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showShare = false" type="primary" size="nomal"
          >确定</el-button
        >
      </div>
    </el-dialog>
    <safety ref="safety"></safety>
  </div>
</template>

<script>
import { get, post, del } from "@/api/index";
import { mapGetters } from "vuex";
// import updateFile from "@/components/updateFile";
import safety from "./safety.vue";
import axios from "axios";
import pako from "pako";
// import pdfPreview from "@/components/pdfPreview";
import { getToken } from "@/utils/auth";
import { dialogMixin } from "@/utils/dialogMixin";
export default {
  mixins: [dialogMixin],
  props: {
    shareUrl: {
      type: String,
      default: ""
    }
  },
  name: "cadItem",
  components: { safety },
  data() {
    const validFile = (rule, value, callback) => {
      if (!value || value.length == 0) {
        callback(new Error("请选择文件"));
      } else {
        callback();
      }
      // }
    };
    const validPhone = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入电话号码"));
      } else if (!this.$isvalidPhone(value)) {
        callback(new Error("请输入正确的11位手机号码"));
      } else {
        callback();
      }
      // }
    };
    return {
      imgraw: null,
      snapshot: require("images/home/work-img.png"),
      // 设计库
      libraryIndex: -1,
      loadingLibrary: false,
      showLibrary: false,
      libraryName: "",
      libraryFile: null,
      libraryList: [],
      innerVisible: false,
      problemData: {
        id: "",
        name: "",
        cover: "",
        typeDictId: "",
        fileList: []
      },
      typeList: [],
      loading: false,
      libraryRules: {
        name: [
          { required: true, message: "请输入设计库名称", trigger: "blur" }
        ],
        typeDictId: [
          { required: true, message: "请输入设计库类型", trigger: "blur" }
        ],
        snapshot: [{ required: true, trigger: "blur", message: "请上传文件" }], //validator: validFile
        cover: [{ required: true, message: "请上传缩略图", trigger: "blur" }]
      },
      //   产品样本
      showManu: false,
      loadingManu: false,
      manuList: [],
      manuName: "",
      //   专家答疑
      showDialog: false,
      loadingBtn: false,
      dialogData: {
        title: "",
        id: "",
        contact: "",
        phone: "",
        content: "",
        file: null,
        problemType: "",
        feedbackTypeId: "",
        fileraw: null,
        snapshot: "",
        status: 1,
        customerUserId: ""
      },
      fileList: [],
      fileraw: null,
      rules: {
        title: [{ required: true, message: "请输入主题", trigger: "blur" }],
        contact: [{ required: true, message: "请输入联系人", trigger: "blur" }],
        content: [{ required: true, message: "请输入内容", trigger: "blur" }],
        phone: [{ required: true, trigger: "blur", validator: validPhone }]
      },
      //   分享
      showShare: false,
      // shareUrl: "https://dendralink.com/#/login",
      addProblem: true,
      // 产品样本查看
      fullscreen: false,
      onViewManu: false,
      loadContent: false,
      viewManuData: {
        name: "",
        fileType: "",
        content: ""
      }
    };
  },
  created() {
    this.initBrary();
    this.initBank();
    this.getData();
  },
  computed: {
    ...mapGetters(["userInfo", "packData"])
  },
  mounted() {},
  methods: {
    getData() {
      // 类型
      post(this.$setApi("/sys/dict/data/page?pageNum=0&pageSize=100"), {
        param: {
          dictType: "design-lib-type"
        }
      }).then(res => {
        this.typeList = [];
        if (res.data && res.data.records && res.data.records.length > 0) {
          res.data.records.map(item => {
            this.typeList.push({
              value: item.id,
              label: item.dictLabel
            });
          });
        }
      });
    },
    getImgUrl(pic) {
      if (pic) return pic;
      else return this.snapshot;
    },
    // 唤起设计库
    onShowSjk() {
      this.initBrary();
      this.showLibrary = true;
    },
    // 搜索设计库
    searchLibrary() {
      this.initBrary();
    },
    cancelDialog(type) {
      this[type] = false;
    },
    initBrary() {
      this.loadingLibrary = false;
      let data = {
        pageNum: 0,
        pageSize: 999,
        param: {}
      };
      if (this.libraryName) data.name = this.libraryName;
      this.libraryList = [];
      get(this.$setApi("/cus/design-lib/type/list"), data)
        .then(res => {
          this.loadingLibrary = true;
          if (res && res.data && res.data.length > 0) {
            this.libraryList = res.data.map(item => {
              return {
                ...item,
                id: item.id,
                name: item.name,
                cover: item.cover
              };
            });
          }
        })
        .catch(() => {
          this.loadingLibrary = true;
        });
    },
    onShowLibrary() {
      this.innerVisible = true;
      this.addProblem = true;
    },
    handleMapChange(file) {
      this.$set(this.problemData, "cover", URL.createObjectURL(file.raw));
      this.fileraw = file.raw;
    },
    handleMapChanges(file) {
      this.dialogData.fileraw = file.raw;
      if (this.$getFileType(file.name) == "image")
        this.$set(this.dialogData, "snapshot", URL.createObjectURL(file.raw));
      else
        this.$set(
          this.dialogData,
          "snapshot",
          require("@/assets/images/fileIcon/" +
            this.$getFileType(file.name) +
            ".png")
        );
    },
    changeLibrary(file) {
      let flieArr = file.name.split(".");
      let suffix = flieArr[flieArr.length - 1];
      if (suffix == "dwg") {
        this.problemData.fileraw = file.raw;
        const lastIndexOfPoint = file.raw.name.lastIndexOf(".");
        const suffix_ = file.raw.name.substring(0, lastIndexOfPoint);
        this.problemData.name = suffix_;
        this.$set(
          this.problemData,
          "snapshot",
          require("@/assets/images/fileIcon/dwg.png")
        );
      } else {
        this.$message.warning("请选择dwg格式文件!!!");
      }
    },
    uploadFileMap(file) {
      console.log("ffffff", file);
    },
    onDoSubmit() {
      this.$validateForm((valid, mess) => {
        if (valid) {
          var postData = new FormData();
          postData.append("id", this.problemData.id);
          postData.append("name", this.problemData.name);
          postData.append("typeDictId", this.problemData.typeDictId);
          if (this.problemData.fileraw)
            postData.append("files", this.problemData.fileraw);
          if (this.fileraw) postData.append("avatar", this.fileraw);
          if (this.addProblem) {
            post(this.$setApi("/cus/design-lib"), postData)
              .then(res => {
                if (res) {
                  this.innerVisible = false;
                  this.$message.success("新增成功");
                  this.resetForm();
                  this.initBrary();
                }
              })
              .catch(err => {
                console.log("失败", err);
                // this.$message.warning(err);
              });
          } else {
            post(this.$setApi("/cus/design-lib/update"), postData)
              .then(res => {
                if (res.data) {
                  this.dialogProblem = false;
                  this.$message.success("编辑成功");
                  this.resetForm();
                  this.init();
                }
              })
              .catch(err => {
                console.log("失败", err);
                // this.$message.warning(err);
              });
          }
        } else {
          this.$message.warning(mess[0].message);
        }
      }, "libraryForm");
    },
    // 删除设计库
    delLibrary(it) {
      this.$confirm("是否删除设计库, 是否继续?", "提示", {
        confirmButtonText定: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          // this.libraryList.splice(idx, 1);
          if (!it.id) return;
          del(this.$setApi("/cus/design-lib/") + it.id).then(res => {
            if (res) {
              this.$message.success("删除成功");
              this.initBrary();
            }
          });
        })
        .catch(() => {});
    },
    onViewLibry(item, index) {
      console.log("onViewLibry", item);
      if (
        item.id &&
        item.files &&
        item.files.length > 0 &&
        item.files[0].url.indexOf(".dwg") > -1
      ) {
        axios({
          url:
            window.configData.VUE_APP_BASEURL +
            "/cus/design-lib/block/" +
            item.id, // 请求地址
          method: "get",
          headers: {
            Authorization: "Bearer " + getToken()
          },
          responseType: "arraybuffer" // 表明返回服务器返回的数据类型
        })
          .then(res => {
           
            this.$emit("addDesign", pako.inflate(res.data, { to: "string" }));
            this.showLibrary = false;
          })
          .catch(err => {
            const buffer = Buffer.from(err.response.data, "utf-8");
            let err_json = JSON.parse(buffer.toString());
            this.$message.warning(err_json.message);
          });
      } else this.$message.warning("文件格式不正确!!!");
    },
    // 唤起产品样本
    onShowMenu() {
      this.initBank();
      this.showManu = true;
    },
    // 产品样本
    searchManu() {
      this.initBank();
    },
    initBank() {
      if (!this.getPackageSimpleByVal("feaProductQuery")) return;
      this.loadingManu = false;
      let data = {
        pageNum: 0,
        pageSize: 999
      };
      if (this.manuName) data.name = this.manuName;
      this.manuList = [];
      get(this.$setApi("/cus/manufacture/type/list"), data)
        .then(res => {
          this.loadingManu = true;
          if (res && res.data && res.data.length > 0) {
            res.data.map(item => {
              // if (item.visible == 0) {
              // let fileList = [];
              // if (item.dataFiles && item.dataFiles.length > 0) {
              //   item.dataFiles.map(it => {
              //     fileList.push({
              //       id: it.id,
              //       address: it.url,
              //       url: it.url,
              //       name: it.fileName
              //     });
              //   });
              // }
              // item.fileList = fileList;
              this.manuList.push(item);
              // }
            });
            console.log("initBank", this.manuList);
          }
        })
        .catch(() => {
          this.loadingManu = true;
        });
    },
    onShowDy() {
      this.dialogData = {
        title: "",
        id: "",
        email: "",
        contact: "",
        phone: "",
        content: "",
        problemType: "专家答疑",
        feedbackTypeId: "19",
        status: 1,
        snapshot: "",
        fileraw: null,
        customerUserId: ""
      };
      let customerUserId = "";
      if (this.userInfo)
        customerUserId = this.userInfo.id || this.userInfo.userId;
      if (customerUserId) {
        this.dialogData.customerUserId = customerUserId;
        this.dialogData.contact =
          this.userInfo.userName || this.userInfo.name || "";
        this.dialogData.phone = this.userInfo.phone || "";

        this.dialogData.email = this.userInfo.email || "";
      }
      this.fileList = [];
      this.showDialog = true;
    },
    // 答疑
    doSubmit() {
      this.validateForm("form", (valid, mess) => {
        if (valid) {
          var postData = new FormData();
          const data = {
            title: "",
            id: "",
            contact: "",
            email: "",
            phone: "",
            content: "",
            problemType: "",
            feedbackTypeId: "",
            status: 1,
            customerUserId: ""
          };
          for (var key in data) {
            postData.append(key, this.dialogData[key]);
          }
          // if (this.fileList.length > 0) {
          //   this.fileList.map(item => {
          //     if (!item.id) postData.append("files", item.raw);
          //   });
          // }
          if (this.dialogData.fileraw)
            postData.append("files", this.dialogData.fileraw);
          post(this.$setApi("/cus/feedback/expert"), postData).then(res => {
            if (res) {
              this.$message({
                message: "问题提交成功",
                type: "success"
              });
              this.showDialog = false;
              this.resetForm();
            }
          });
        } else {
          this.$message.warning(mess[0].message);
        }
      });
    },
    validateForm(formName = "form", call) {
      const promises = [this.$refs[formName]];
      let count = 0;
      for (let index = 0; index < promises.length; index++) {
        const element = promises[index];
        element.validate((result, errors) => {
          if (result) {
            count++;
            if (count == promises.length) {
              call(true);
              return;
            }
          } else {
            const fkey = Object.keys(errors)[0];
            call(false, errors[fkey]);
            return;
          }
        });
      }
    },
    resetForm() {
      this.problemData = {
        id: "",
        name: "",
        cover: "",
        fileraw: "",
        snapshot: "",
        typeDictId: "",
        fileList: []
      };
      this.dialogData = {
        title: "",
        id: "",
        contact: "",
        phone: "",
        email: "",
        content: "",
        problemType: "",
        feedbackTypeId: "",
        status: 1,
        customerUserId: "",
        fileraw: "",
        snapshot: ""
      };
      this.fileList = [];
    },
    // 行业规范
    showSafety() {
      if (!this.getPackageSimpleByVal("feaStandardQuery")) return;
      this.$refs.safety.onOpened();
    },
    // 产品样本详细资料
    downManuData(item) {
      post(this.$setApi("/cus/manufacture/count/") + item.id).then(res => {
        console.log("访问量", res);
      });
      this.viewManuData = {
        name: item.name,
        fileType: "",
        content: ""
      };
      get(this.$setApi("/cus/manufacture/") + item.id)
        .then(res => {
          console.log("downManuData", res);
          this.loadContent = true;
          if (res && res.data && (res.data.content || res.data.dataFiles)) {
            let datas = res.data;
            if (datas.dataFiles && datas.dataFiles.length > 0) {
              let name = datas.name;
              let content = datas.content;
              let fileType = this.$getFileType(datas.dataFiles[0].fileName);
              console.log("fileType", fileType);
              if (
                fileType == "excel" ||
                fileType == "image" ||
                fileType == "other"
              ) {
                this.$message.warning("暂无数据");
                this.onViewManu = false;
                return;
              }
              if (fileType == "pdf") {
                content = datas.dataFiles[0].url;
                name += `-${datas.dataFiles[0].fileName}`;
              }
              this.viewManuData.fileType = fileType;
              this.viewManuData.content = content;
              this.onViewManu = true;
              this.loadContent = false;
            }
          } else {
            this.$message.warning("暂无数据");
            this.onViewManu = false;
          }
        })
        .catch(() => {
          this.loadContent = false;
          this.$message.warning("暂无数据");
          this.onViewManu = false;
        });
    }
  },
  watch: {}
};
</script>
<style lang="stylus" scoped>
@import '~@/assets/styl/sjk'

.cad-item-box
  .el-button
    &:first-child
      margin-left 8px

.cad-dialog-search
  margin-bottom 24px
  justify-content flex-end

  >>>.el-input--small
    width 50%
    margin-right 16px

  >>>.el-input--small .el-input__inner
    height 40px
    line-height 40px

.cad-dialog-container
  width 100%
  padding 0px
  max-height 350px
  overflow-y auto

.cad-dialog-title
  font-size 16px
  margin-bottom 12px

.cad-dialog-item-box
  flex-wrap wrap

// 产品样本
.cad-dialog-container
  width 100%
  height 400px
  padding-top 16px

.cad-manu-item
  position relative
  overflow hidden
  width 164pxs
  height 260px
  flex-direction column
  margin-bottom 16px
  margin-right 16px
  background #FFFFFF
  box-shadow 0px 8px 20px 0px rgba(9, 43, 80, .2)
  border-radius 8px
  box-sizing border-box

  &:nth-child(4n)
    margin-right 0px

  .cad-manu-down
    position absolute
    top 10px
    right 10px
    color #1caaef
    font-size 12px
    cursor pointer

  .manu-item-info
    width 100%
    padding 12px 12px 5px 12px
    text-align left
    min-height 74px

    .manu-title
      font-size 14px
      color #222
      font-weight 500

    .manu-contact
      text-align left
      font-size 12px
      font-weight 400
      color #999
      line-height 20px

  .manu-item-img
    overflow hidden
    width 140px
    height 105px
    margin 0 12px
    background #f6f6f6

    img
      width 100%
      min-height 100%

  .manu-item-desc
    padding 8px 8px
    width 140px
    min-height 50px
    background #f6f6f6

    .manu-hidden
      padding 0 0px
      font-size 12px
      color #999
      line-height 17px

.share-contnet
  height 70px
  font-size 14px
  color #666
  font-weight 400

  span
    margin-left 24px
    color #3478F7
    cursor pointer

.avatar-uploader
  img
    width 100%

iframe
  width 100%
  min-height 500px
  height 100%

.view-manu-box
  position relative
  width 100%
  height 520px
  font-size 13px
  color #666
  overflow-x auto
  overflow-y auto

  >>>img, >>>video
    width 100%

.is-fullscreen
  >>>.el-dialog__body
    height calc(100% - 160px)

.zjdyfj
  background #fff !important
  border none !important
  height auto

  >>>.el-upload
    border none !important
    height auto
    align-items flex-start !important

.zdyy-dialog
  >>>.el-dialog__body
    padding 16px 20px 16px 20px !important

  .zjdyfj-uploader, .zjdyfj-uploader >>>.el-upload
    height 80px !important
</style>
