<template>
  <div>
    <!-- 线形管理-->
    <el-dialog
      title="线形管理"
      :visible.sync="showLine"
      width="680px"
      :append-to-body="true"
      :close-on-click-modal="false"
      @close="showLine = false"
      class="custem-dialog"
      v-dialogDrag
      v-disable-contextmenu
    >
      <div class="dialog-body-box line-container">
        <div class="cad-select_wrapper" v-stopdrag style="height: 405px;">
          <div class="block-wrapper">
            <div class="flex line-top-wrapper">
              <!-- <div class="line-left-box">
              <div class="polar-axis-title-box">线型过滤器</div>
              <div class="flex line-select-box">
                <el-select v-model="lineTypeVal" placeholder="请选择">
                  <el-option
                    v-for="(item, idx) in lineList"
                    :key="idx"
                    :label="item.name"
                    :value="item.name"
                  >
                  </el-option>
                </el-select>
                <el-checkbox
                  v-model="isRate"
                  style="margin-left:25px;margin-bottom: 0px;"
                  @change="changeRate"
                  >反转过滤器江(I))</el-checkbox
                >
              </div>
            </div> -->
              <div class="flex line-right-box">
                <el-button @click="showLoadLine" type="primary" size="mini">
                  加载</el-button
                >
                <!-- <el-button @click="delLine" type="warning" size="mini">
                删除</el-button
              > -->
              </div>
            </div>
            <div class="line-tips-box">
              <el-input
                :value="'当前线型为：' + onLineName"
                readonly
                style="margin-left:0;"
              />
            </div>
            <div class="scroll-Y1 line-contont-box">
              <div class="line-content" ref="box">
                <div class="flex line-item-top line-item-box">
                  <div class="line-item-left line-item">线型</div>
                  <!-- <div class="line-item-mid line-item">外观</div> -->
                  <div class="line-item-right line-item">说明</div>
                </div>

                <template v-if="lineList.length > 0">
                  <div
                    class="flex cursor line-item-box"
                    v-for="(item, index) in lineList"
                    :key="index"
                    :class="{
                      'list-item-active': onLineName && item.name == onLineName
                    }"
                    @dblclick="selectLineData(item)"
                  >
                    <div class="line-item-left line-item">{{ item.name }}</div>
                    <!-- <div class="line-item-mid line-item">{{ item.icon }}</div> -->
                    <div class="line-item-right line-item">
                      <!-- <el-tooltip
                      class="item"
                      effect="dark"
                      :content="item.comments"
                      placement="top"
                    > -->
                      <div class="line-hidden">
                        {{ item.comments }}
                      </div>
                      <!-- </el-tooltip> -->
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div slot="footer" class="flex-center block-footer">
        <el-button @click="showLine = false" size="nomal" native-type="button"
          >取消</el-button
        >
        <el-button @click="sureLine" type="primary" size="nomal"
          >确定</el-button
        >
      </div>
      <el-dialog
        title="加载或重载线型"
        :visible.sync="loadingLine"
        width="600px"
        :append-to-body="true"
        :close-on-click-modal="false"
        @close="loadingLine = false"
        class="custem-dialog"
        v-dialogDrag
        v-disable-contextmenu
      >
        <div class="dialog-body-box line-container">
          <div class="cad-select_wrapper" v-stopdrag>
            <div class="block-wrapper">
              <div class="flex line-top-wrapper">
                <el-button @click="submitLine" type="primary" size="mini">
                  加载</el-button
                >
                <input
                  ref="fileLine"
                  type="file"
                  style="display: none"
                  @change="uploadLine"
                  accept=".lin"
                />
                <el-input :value="fileName" readonly />
              </div>

              <div
                class="scroll-Y1 line-contont-box"
                style="height:calc(100% - 32px)"
              >
                <div class="line-content" ref="box">
                  <div class="flex line-item-top line-item-box">
                    <div class="line-item-left line-item">线型</div>
                    <div class="line-item-right line-item">说明</div>
                  </div>
                  <template v-if="useList.length > 0">
                    <el-checkbox-group v-model="checkList" @change="changeLine">
                      <el-checkbox
                        :label="item"
                        v-for="(item, index) in useList"
                        :key="index"
                      >
                        <div class="flex cursor  line-item-box">
                          <div class="line-item-left line-item">
                            {{ item.name }}
                          </div>
                          <div class="line-item-right line-item">
                            <el-tooltip
                              class="item"
                              effect="dark"
                              :content="item.comments"
                              placement="top"
                            >
                              <div class="line-hidden">
                                {{ item.comments }}
                              </div>
                            </el-tooltip>
                          </div>
                        </div>
                      </el-checkbox>
                    </el-checkbox-group>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div slot="footer" class="flex-center block-footer">
          <el-button
            @click="loadingLine = false"
            size="nomal"
            native-type="button"
            >取消</el-button
          >
          <el-button @click="sureLoadLine" type="primary" size="nomal"
            >确定</el-button
          >
        </div>
      </el-dialog>
    </el-dialog>

    <!-- <div
      class="mask-wrapper"
      v-if="showLine"
    /> -->
  </div>
</template>
<script>
import { get, post, del } from "@/api/index";
export default {
  data() {
    return {
      showLine: false,
      isRate: false,
      lineTypeVal: "显示所有线型",
      lineTypeList: [
        {
          name: "显示所有线型",
          id: ""
        }
      ],
      lineVal: " ",
      oldLineList: [],
      lineList: [],
      //   是否加载
      loadingLine: false,
      onLineData: null,
      fileName: "",
      useList: [],
      selectUseList: [],
      checkList: [],
      isSelect: false
    };
  },
  computed: {
    onLineName() {
      return (this.onLineData && this.onLineData.name) || "";
    }
  },
  mounted() {
    this.$nextTick(() => {
      //   this.dragControllerDiv();
    });
  },
  methods: {
    onShowLine(pixiMap) {
      this.lineList = [];
      this.onLineData = null;
      this.oldLineList = [];
      let lineList = Array.from(window.cadmap.dwgservice._linetypes).map(
        item => {
          if (Array.isArray(item) && item.length == 2) {
            return {
              name: item[1]._name,
              comments: item[1]._description
            };
          }
        }
      );
      if (lineList && lineList.length > 0) {
        this.lineList = lineList;
        this.oldLineList = JSON.parse(JSON.stringify(lineList));
        this.onLineData = this.lineList[0];
      }
      this.showLine = true;
    },
    dragControllerDiv() {
      var resize = document.getElementsByClassName("line-item-mid");
      var left = document.getElementsByClassName("line-item-left");
      var mid = document.getElementsByClassName("line-item-right");
      var box = document.getElementsByClassName("line-item-box");
      for (let i = 0; i < resize.length; i++) {
        // 鼠标按下事件
        resize[i].onmousedown = function(e) {
          //颜色改变提醒
          //   resize[i].style.background = "#818181";
          var startX = e.clientX;
          resize[i].left = resize[i].offsetLeft;
          // 鼠标拖动事件
          document.onmousemove = function(e) {
            var endX = e.clientX;
            var moveLen = resize[i].left + (endX - startX); // （endx-startx）=移动的距离。resize[i].left+移动的距离=左边区域最后的宽度
            var maxT = box[i].clientWidth - 200; // 容器宽度 - 左边区域的宽度 = 右边区域的宽度

            if (moveLen >= maxT) moveLen = maxT; //右边区域最小宽度为150px

            resize[i].style.left = moveLen; // 设置左侧区域的宽度

            for (let j = 0; j < left.length; j++) {
              let width = box[i].clientWidth - moveLen - left[i].style.width;
              if (width >= 80) {
                resize[j].style.width = moveLen + "px";
                mid[j].style.width = width + "px";
              }
            }
          };
          // 鼠标松开事件
          document.onmouseup = function(evt) {
            //颜色恢复
            // resize[i].style.background = "#d6d6d6";
            document.onmousemove = null;
            document.onmouseup = null;
            resize[i].releaseCapture && resize[i].releaseCapture(); //当你不在需要继续获得鼠标消息就要应该调用ReleaseCapture()释放掉
          };
          resize[i].setCapture && resize[i].setCapture(); //该函数在属于当前线程的指定窗口里设置鼠标捕获
          return false;
        };
      }
    },

    // 加载线型
    showLoadLine() {
      this.loadingLine = true;
      this.checkList = [];
      this.useList = [];
      this.fileName = "";
    },
    // 删除线型
    delLine() {
      if (this.checkList.length == 0) {
        this.$message.warning("请选择要删除的数据!!!");
        return;
      }
      this.$confirm("是否删除" + this.lineVal + ", 是否继续?", "提示", {
        confirmButtonText定: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          del(
            this.$setApi("/cus/line-type/ids/") + this.checkList.join(",")
          ).then(res => {
            if (res) {
              this.$message.success("删除成功");
              this.onShowLine();
            }
          });
        })
        .catch(() => {});
    },
    // 上传
    submitLine() {
      this.$refs.fileLine.click();
    },
    // 上传线型
    uploadLine(event) {
      // 文件上传之后的回调
      const fileList = event.target.files || event.dataTransfer.files; // 上传的文件数组
      const fileData = fileList[0];
      console.log("uploadLine", fileData);
      if (fileData && fileData.name && fileData.name.indexOf(".lin") > -1) {
        console.log("fileData", fileData);
        let postData = new FormData();
        postData.append("file", fileData);
        this.fileName = fileData.name;
        post(this.$setApi("/cus/line-type"), postData).then(res => {
          if (res && res.data && typeof res.data == "string") {
            let datas = JSON.parse(res.data);
            console.log("上传", JSON.parse(res.data));
            if (datas.length > 0) {
              datas.map(item => {
                this.useList.push({
                  name: item.name,
                  comments: item.comments,
                  pattern: item.pattern,
                  patterndwg: item.patterndwg
                });
              });
            }
          }
        });
      } else this.$message.warning("请上传lin格式文件");
    },
    getLineData(id) {
      get(this.$setApi("/cus/line-type/content/" + id)).then(res => {
        console.log("getLineData", res);
      });
    },
    // 反选
    changeRate() {
      this.checkList = this.lineList
        .filter(item => {
          return !this.checkList.includes(item.id);
        })
        .map(item => {
          return item.id;
        });
    },
    changeLine() {
      console.log("changeLine", this.checkList);
    },
    // 加载线型
    sureLoadLine() {
      if (this.checkList.length > 0) {
        this.checkList.map(item => {
          let index = -1;
          this.lineList.some((it, idx) => {
            if (it.name == item.name) {
              index = idx;
              return true;
            }
          });
          if (index == -1) this.lineList.unshift(item);
        });
      }
      this.loadingLine = false;
    },
    // 双击选择线型
    selectLineData(item) {
      this.onLineData = item;
    },
    // 保存线型
    sureLine() {
      // this.lineList:原本底层数据+选择的数据，this.checkList:选择的数据
      window.cadmap.dwgservice.setLineTypesByOnline(this.checkList);
      window.cadmap.dwgservice.setCurrentLineType(this.onLineData.name);
      this.showLine = false;
    }
  }
};
</script>
<style scoped lang="stylus">
@import '~@/assets/styl/cad'

.line-container
  max-height 540px

  .block-wrapper
    height 100%
    padding 0 16px

  .line-top-wrapper
    align-items flex-start
    justify-content space-between

  .line-left-box
    position relative
    padding 12px
    border 1px solid #dcdcdc
    width 70%

  .line-right-box
    width 25%
    justify-content flex-start

  .line-select-box
    align-items center

  .line-tips-box
    margin 12px 0

  .line-contont-box
    margin 8px 0 0 0
    height calc(100% - 95px)
    padding 8px
    border-radius 8px
    border 1px solid #888d96

    >>>.el-checkbox
      width 100%

  .line-item
    display flex
    align-items center
    padding 12px 8px

  .line-item-left
    width 150px

  .line-item-mid
    min-width 200px
    flex 1

  .line-item-top
    .line-item-mid
      border-left 1px solid #c0c3c7
      border-right 1px solid #c0c3c7

  .line-item-right
    // width 100px
    width 438px
    line-height 1.2

  .line-hidden
    overflow hidden
    text-overflow ellipsis
    white-space nowrap

.list-item-active
  background #409eff

  div
    color #fff !important
</style>
