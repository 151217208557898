<template>
  <div>
    <!-- 喷淋设计 -->
    <el-dialog
      title="喷淋设计"
      :visible.sync="showSpray"
      width="620px"
      :append-to-body="true"
      :close-on-click-modal="false"
      @close="cancelSpray"
      class="custem-dialog"
      v-dialogDrag
      v-disable-contextmenu
    >
      <div class="dialog-body-box">
        <div class="cad-select_wrapper scroll-Y" v-stopdrag v-closeSelect>
          <div v-for="(item, idx) in sprayData" :key="idx">
            <div class="block-wrapper" v-if="item.type == 'check' && item.show">
              <div class="spray-item-box">
                <div class="spray-title">{{ item.label }}</div>
                <el-checkbox-group v-model="item.value" size="mini">
                  <el-checkbox
                    v-for="(item, index) in layersList"
                    :label="item.id"
                    :key="index"
                  >
                    <el-tooltip
                      class="item"
                      effect="dark"
                      :content="item.name"
                      placement="top"
                    >
                      <span> {{ item.name }}</span>
                    </el-tooltip>
                  </el-checkbox>
                </el-checkbox-group>
              </div>
              <div style="display:flex;justify-content:flex-end;">
                <el-button @click="onpreview" size="nomal" native-type="button"
                  >预览</el-button
                >
              </div>
            </div>

            <div
              class="flex align-start text-item spray-input-box"
              v-else-if="item.type == 'select' && item.show"
            >
              <div classs="block-title" style="width: 165px">
                {{ item.label }}:
              </div>
              <div class="spray-input">
                <div class="spray-select-box">
                  <el-select
                    v-model="item.value"
                    ref="refpray"
                    placeholder="请选择"
                    :disabled="item.disabled"
                    @change="value => changeSelect(item, idx, value)"
                    @visible-change="
                      val => visbleChage(item, idx, item.value, val)
                    "
                  >
                    <el-option
                      v-for="(ele, i) in item.list"
                      :key="i"
                      :label="ele"
                      :value="ele"
                    >
                    </el-option>
                  </el-select>
                  <el-select
                    v-if="item.isOpen"
                    v-model="item.val"
                    placeholder="请选择"
                    ref="refpray1"
                    :disabled="item.disabled"
                    @change="val => changeTwo(val, idx)"
                  >
                    <el-option
                      v-for="(im, ii) in item.twoList"
                      :key="ii"
                      :label="im"
                      :value="im"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>
            <template
              v-else-if="
                item.type == 'input' &&
                  item.show &&
                  item.key == 'distanceToWall'
              "
            >
              <div class="flex align-start text-item spray-input-box">
                <div classs="block-title" style="width: 165px">
                  不设置主干管与墙体距离:
                </div>
                <div class="spray-input">
                  <!-- <el-radio-group v-model="isSetting" style="margin-left: 15px">
                  <el-radio :label="true">是</el-radio>
                  <el-radio :label="false">否</el-radio>
                </el-radio-group> -->
                  <el-checkbox
                    v-model="isSetting"
                    style="margin-left: 15px"
                  ></el-checkbox>
                </div>
              </div>
              <div class="flex align-start text-item spray-input-box">
                <div classs="block-title" style="width: 165px">
                  {{ item.label }}:
                </div>
                <div class="spray-input">
                  <el-input
                    v-model="item.value"
                    :disabled="item.disabled || isSetting"
                    @input="item.value = checkGreaterZero(item.value, 'number')"
                    style="margin-left: 15px"
                  ></el-input>
                </div>
              </div>
              <div class="spray-input" v-if="isDevelopment">
                  <el-input v-model="configText" style="margin-left: 15px"></el-input>
              </div>
            </template>
            <div
              v-else-if="item.type == 'input' && item.show"
              class="flex align-start text-item spray-input-box"
            >
              <div classs="block-title" style="width: 165px">
                {{ item.label }}:
              </div>
              <div class="spray-input">
                <el-input
                  v-model="item.value"
                  :disabled="item.disabled"
                  @input="item.value = checkGreaterZero(item.value, 'number')"
                  style="margin-left: 15px"
                ></el-input>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div slot="footer" class="flex-center block-footer">
        <el-button @click="importConfig" v-if="isDevelopment" size="nomal" native-type="button"
          >导入</el-button
        >
        <el-button @click="exportConfig" v-if="isDevelopment" size="nomal" native-type="button"
          >导出</el-button
        >
        <el-button @click="cancelSpray" size="nomal" native-type="button"
          >取消</el-button
        >
        <el-button @click="sureSpray" type="primary" size="nomal"
          >确定</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      width="25%"
      title="自定义"
      :visible.sync="innerVisible"
      :close-on-click-modal="false"
      append-to-body
      v-dialogDrag
    >
      <div style="text-align:center" class="form-wrapper">
        <el-input
          v-model="sprayVal"
          class="form-input"
          @input="sprayVal = regValue(sprayVal)"
        />
        <div slot="footer" class="dialog-footer">
          <el-button @click="onDoSubmit" type="primary" size="nomal"
            >确定</el-button
          >
          <el-button
            @click="innerVisible = false"
            size="nomal"
            native-type="button"
            >关闭</el-button
          >
        </div>
      </div>
    </el-dialog>
    <div class="mask-wrapper" v-if="showSpray" />
  </div>
</template>
<script>
import { get, post } from "@/api/index";
import { CommandFactory, CommandStatus } from "@/cadplugins/map/commandfactory";
import { ConfigManager } from "@/business/rule/configManager.js";
import { closeSelectMixin } from "@/utils/cadMinxins/closeSelectMixin"; //关闭下拉
import { SprinkPreview } from "@/business/tools/drawsprinkler.js"; //预览弹窗
import {
  DangerLevel,
  SprayConfigs,
  SprinklerType,
  SprinklerSettingMethod,
  getActionAreaList,
  getSprayStrengthList
} from "@/business/rule/config.js";
import { dialogMixin } from "@/utils/dialogMixin";
export default {
  props: {
    supThis: {
      type: Object
    }
  },
  mixins: [closeSelectMixin, dialogMixin],
  data() {
    return {
      productId: "",
      // 喷淋设计界面
      initSpray: false,
      showSpray: false,
      sprayList: [],
      selectLayers: [],
      layersList: [],
      showRelation: false,
      relationshipList: [],
      // 危险级别
      warningVal: "",
      warningList: [],
      areaVal: "",
      areaList: [],
      strongVal: "",
      strongList: [],
      psTypeVal: "",
      psTypeList: [],
      sprinklerSettingVal: "",
      sprinklerSettingList: [],
      distanceToWall: 400,
      sprayForm: {
        BuildingCategory: "",
        DangerLevel: "",
        ActionArea: "",
        SprinklerCategory: ""
      },
      sprayIndex: -1,
      isSetting: false,
      sprayData: this.returnSprayData(),
      innerVisible: false,
      sprayVal: "",
      showInput: ["仓库型特殊应用喷头", "早期抑制快速响应喷头"],
      isDevelopment: false,//是否显示导入导出配置按钮
      configText: "",//配置导出导入值
      defaultConfig: "",
    };
  },
  mounted() {
    this.$nextTick(() => {});
    //保存配置的默认值，供界面初始化，避免界面显示的是上一个项目的参数
    let datas = {};
    this.sprayData.map(item => {
      datas[item.key] = item.value;
      if (item.key1) 
        datas[item.key1] = item.val || "";
    });
    this.defaultConfig = JSON.stringify(datas);
  },
  methods: {
    // 规定typeName为number表单只能输入数字、小数点和负号
    checkGreaterZero(value, type) {
      if (type == "number") value = value.replace(/[^\-?\d.]/g, "");
      // value
      //   .replace(/\D*(\d*)(\.?)(\d{0,3})\d*/, "$1$2$3") // 只能输入数字、小数点限制3位
      //   .replace(/^0+(\d)/, "$1") // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
      //   .replace(/^\./, "0.") // 如果输入的第一位为小数点，则替换成 0. 实现自动补全
      //   .match(/^\d*(\.?\d{0,3})/g)[0] || ""; // 数字开头、小数点3位
      return value;
    },
    // 喷淋设计-start
    initSprayDialog() {
      const _this = this;
      //危险级别
      this.warningList = [];
      this.distanceToWall = 400;
      for (let level of DangerLevel) {
        this.warningList.push({
          name: level,
          value: level
        });
      }
      this.warningVal = DangerLevel[0];
      //作用面积
      this.areaList = [];
      let actionAreaList = getActionAreaList(this.warningVal);
      for (let area of actionAreaList) {
        this.areaList.push({
          name: area,
          value: area
        });
      }
      this.areaVal = actionAreaList[0];
      //喷水强度
      this.strongList = [];
      let sprayStrengthList = getSprayStrengthList(
        this.warningVal,
        this.areaVal
      );
      for (let strength of sprayStrengthList) {
        this.strongList.push({
          name: strength,
          value: strength
        });
      }
      this.strongVal = sprayStrengthList[0];
      this.psTypeList = [];
      for (let type of SprinklerType) {
        this.psTypeList.push({
          name: type,
          value: type
        });
      }
      this.psTypeVal = SprinklerType[0];
      //喷头设置方式
      this.sprinklerSettingList = [];
      for (let method of SprinklerSettingMethod) {
        this.sprinklerSettingList.push({
          name: method,
          value: method
        });
      }
      this.sprinklerSettingVal = SprinklerSettingMethod[0];
      //参与计算的图层
      let layers = window.cadmap.getLayers();
      this.layersList = [];
      for (let layer of layers) {
        if (layer.name.indexOf("D-") != 0)
          //过滤掉后台添加的图层
          this.layersList.push({ id: layer.name, name: layer.name });
      }
      //默认选择这些图层
      this.selectLayers = ["WALL", "WINDOW", "COLUMN"];
      this.supThis.getProjectData().then(res => {
        if (res && res.spray) {
          _this.setSprayConfig(res.spray);
        }
        else{
          _this.setSprayConfig(_this.defaultConfig);
        }
        _this.showSpray = true;
      });
    },
    setSprayConfig(sprayConfig) {
      let sprayData = JSON.parse(sprayConfig);
      this.isSetting = sprayData.isSetting || false;
      this.sprayData.map(item => {
        item.value = sprayData[item.key] || "";
        if (
          sprayData[item.key] &&
          sprayData[item.key] == "自定义" &&
          sprayData[item.key1]
        )
          item.value = `自定义-${sprayData[item.key1]}`;
        if (item.key1) item.val = sprayData[item.key1];
        if (
          item.label == "喷头类型" &&
          sprayData.SprinklerCategory &&
          item.isHaveTwo.indexOf(sprayData.SprinklerCategory) > -1
        ) {
          item.isOpen = true;
          item.val = sprayData[item.key1];
          item.twoList = item.twoData[sprayData.SprinklerCategory];
        }
        if (sprayData.BuildingCategory) {
          if (sprayData.BuildingCategory.indexOf("仓库") > -1) {
            if (item.isReadOnly) item.disabled = false;
          } else if (item.isReadOnly) item.disabled = true;
          this.isShelveChange(this.isShelve());
        }
        if (item.isInput) {
          item.show =
            this.showInput.indexOf(sprayData.SprinklerCategory) > -1
              ? true
              : false;
        }
        if (item.label.indexOf("参与") > -1) {
          item.value = sprayData.selectLayers || [];
        }
        return item;
      });
    },
    // 唤起事件
    onShowSpray(pixiMap, productId) {
      this.productId = productId;
      if (!this.showSpray) {
        this.initSprayDialog();
      }
      //测试用
      this.isDevelopment = false && process.env.NODE_ENV === "development";
    },
    //导入参数事件
    importConfig(){
      this.setSprayConfig(this.configText);
    },
    //导出参数事件
    exportConfig(){
      let datas = {};
      this.sprayData.map(item => {
        datas[item.key] = item.value;
        if (item.key1) datas[item.key1] = item.val || "";
      });
      this.configText = JSON.stringify(datas);
    },
    changeSelect(item, idx, val) {
      this.sprayIndex = idx;
      if (item.label == "建筑定性") {
        if (val.indexOf("仓库") > -1) {
          this.sprayData.map(item => {
            if (item.typeName && item.typeName.indexOf("仓库") > -1) {
              item.show = true;
            }
            if (item.isReadOnly) item.disabled = false;
            this.isShelveChange(this.isShelve());
            return item;
          });
        } else {
          this.sprayData.map(item => {
            if (item.typeName && item.typeName.indexOf("仓库") > -1) {
              item.show = false;
            }
            if (item.isReadOnly) item.disabled = true;
            this.isShelveChange(false);
            return item;
          });
        }
      } else if (item.label == "喷头类型") {
        if (item.typeName && item.typeName.indexOf("仓库") > -1) {
          item.show = false;
        } else if (item.isHaveTwo.indexOf(val) > -1) {
          item.isOpen = true;
          item.twoList = item.twoData[val];
        } else {
          item.isOpen = false;
          item.twoList = [];
        }
        this.sprayData.map(ele => {
          if (ele.isInput)
            ele.show = this.showInput.indexOf(val) > -1 ? true : false;
          return ele;
        });
      } else if (item.label == "是否设置货架内置喷头") {
        let isShow = this.isShelve();
        this.isShelveChange(isShow);
      } else {
        if (typeof val == "string" && val.indexOf("自定义") > -1) {
          this.innerVisible = true;
          let val = JSON.parse(
            JSON.stringify(this.sprayData[this.sprayIndex].val)
          );
          this.sprayData[this.sprayIndex].value = `自定义-${val}`;
          this.sprayVal = JSON.parse(
            JSON.stringify(this.sprayData[this.sprayIndex].val)
          );
        }
      }
    },
    visbleChage(item, idx, val, value) {
      if (!value) {
        if (typeof val == "string" && val.indexOf("自定义") > -1) {
          this.sprayIndex = idx;
          this.innerVisible = true;
          let val = JSON.parse(
            JSON.stringify(this.sprayData[this.sprayIndex].val)
          );
          this.sprayData[this.sprayIndex].value = `自定义-${val}`;
          this.sprayVal = JSON.parse(
            JSON.stringify(this.sprayData[this.sprayIndex].val)
          );
        }
      }
    },
    isShelve() {
      let isShelve = false;
      this.sprayData.some(item => {
        if (item.key == "IsSetSprayInShelves" && item.value == "是") {
          isShelve = true;
          return true;
        }
      });
      return isShelve;
    },
    isShelveChange(isShelve) {
      let keyList = [
        "ShelfFloors",
        "MinPressureGz",
        "ShelfCoefficientK",
        "NumberOfOpenSprinklers"
      ];
      this.sprayData.map(ele => {
        if (keyList.indexOf(ele.key) > -1) ele.show = isShelve;
        return ele;
      });
      if (isShelve) {
        this.sprayData.map(item => {
          if (item.typeName && item.typeName.indexOf("仓库") > -1) {
          } else if (!item.isAlways) {
            if (item.isWareHouse) item.show = true;
            else item.show = false;
          }
          return item;
        });
      } else {
        this.sprayData.map(item => {
          if (item.typeName && item.typeName.indexOf("仓库") > -1) {
          } else if (!item.isAlways) {
            if (!item.isWareHouse) item.show = true;
            else item.show = false;
          }
          return item;
        });
      }
    },
    changeTwo(val, idx) {
      this.sprayIndex = idx;
      if (
        this.sprayData[this.sprayIndex] &&
        this.sprayData[this.sprayIndex].val
      )
        this.sprayData[this.sprayIndex].val = val;
      else this.$set(this.sprayData[this.sprayIndex], "val", val);
    },
    regValue(val) {
      return val.replace(/^([0-9]\d*\.?\d{0,2})?.*$/, "$1");
    },
    onDoSubmit() {
      let val = JSON.parse(JSON.stringify(this.sprayVal));
      this.sprayData[this.sprayIndex].val = val;
      this.sprayData[this.sprayIndex].value = `自定义-${val}`;
      this.innerVisible = false;
    },
    //选择喷水强度
    changeStrong() {
    },
    // 点击确定事件
    sureSpray() {
      let datas = {};
      this.sprayData.map(item => {
        datas[item.key] = item.value;
        if (item.key1) datas[item.key1] = item.val || "";
      });
      datas.isSetting = this.isSetting;

      //尝试通过方法获取一下，如果没报错则表明参数合理
      try {
        ConfigManager.getInfo(
          datas.SprinklerCategory,
          datas.IsTallRoom == "是",
          datas.BuildingCategory,
          datas.IsSetSprayInShelves == "是",
          datas.ActionArea,
          datas.SprayStrength,
          datas.DangerLevel,
          datas.CoefficientK,
          datas.MinPressure,
          datas.MinPressureGz,
          datas.SprinklerSettingMethod,
          datas.ShelfCoefficientK,
          datas.ShelfFloors,
          datas.CustomMaxDisBetweenSprinklers,
          datas.CustomMinDisBetweenSprinklers,
          datas.CustomMaxDisWithWall,
          datas.CustomMinDisWithWall
        );
      } catch (e) {
        this.$message.warning("喷淋配置参数异常，具体原因为：" + e.message);
        return;
      }

      if (datas.selectLayers.length == 0 && datas.BuildingCategory != "仓库") {
        this.$message.warning("请先选择图层");
        return;
      }
      if (datas.distanceToWall == "" && datas.BuildingCategory != "仓库") {
        this.$message.warning("主干管与墙体距离不能为空");
        return;
      }
      this.supThis.saveProjectData("spray", JSON.stringify(datas)).then(res => {
        res;
        this.showSpray = false;
      });
    },
    previewCommand: function*(map, params) {
      CommandFactory.getInstance().execYieldCommand(
        "spirepreview",
        map,
        params
      );
      let select = yield;
      this.sprayData.map(item => {
        if (item.key == "selectLayers") {
          item.value = select.value;
        }
      });
      this.showSpray = true;
      return { value: {}, done: CommandStatus.eDone };
    },
    onpreview() {
      this.showSpray = false;
      let datas = {};
      this.sprayData.map(item => {
        datas[item.key] = item.value;
      });

      let params = {
        layers: datas.selectLayers
      };
      CommandFactory.getInstance().execCommandFun(
        "preview",
        this.previewCommand,
        this.supThis.pixiMap,
        params
      );
    },
    // 取消喷淋事件
    cancelSpray() {
      this.showSpray = false;
      this.showRelation = false;
      this.closeDialog();
    },
    // 导入喷淋数据
    addSpray() {
      this.$refs.fileBtn.click();
    },
    // 喷淋设计-end

    returnSprayData() {
      return [
        {
          label: "建筑定性",
          key: "BuildingCategory",
          value: "单多层公共建筑",
          val: "",
          type: "select",
          disabled: false,
          show: true,
          isAlways: true,
          list: [
            "100米以上住宅",
            "一类高层公共建筑",
            "二类高层公共建筑",
            "单多层公共建筑",
            "仓库",
            "厂房"
          ]
        },
        {
          label: "火灾危险等级",
          key: "DangerLevel",
          value: "中危险II级",
          val: "",
          type: "select",
          disabled: false,
          show: true,
          isAlways: true,
          list: [
            "轻危险级",
            "中危险I级",
            "中危险II级",
            "严重危险I级",
            "严重危险II级",
            "仓库危险级I级",
            "仓库危险级II级",
            "仓库危险级III级"
          ]
        },
        {
          label: "作用面积（㎡）",
          key: "ActionArea",
          key1: "ActionArea1",
          value: "160",
          val: "",
          type: "select",
          disabled: false,
          show: true,
          isAlways: true,
          custom: true,
          list: ["160", "200", "240", "260", "280", "自定义"]
        },
        {
          label: "喷头类型",
          key: "SprinklerCategory",
          key1: "SprinklerCategory1",
          value: "标准覆盖面积洒水喷头",
          val: "标准响应喷头",
          type: "select",
          disabled: false,
          show: true,
          isAlways: true,
          isOpen: true,
          isHaveTwo: [
            "标准覆盖面积洒水喷头",
            "扩大覆盖面积洒水喷头",
            "边墙型喷头"
          ],
          list: [
            "标准覆盖面积洒水喷头",
            "扩大覆盖面积洒水喷头",
            "非仓库型特殊应用喷头",
            "仓库型特殊应用喷头",
            "早期抑制快速响应喷头",
            "边墙型喷头"
          ],
          twoList: [],
          twoData: {
            标准覆盖面积洒水喷头: [
              "快速响应喷头",
              "特殊响应喷头",
              "标准响应喷头"
            ],
            扩大覆盖面积洒水喷头: ["快速响应喷头", "标准响应喷头"],
            边墙型喷头: ["标准覆盖面积洒水喷头", "扩大覆盖面积洒水喷头"]
          }
        },
        {
          label: "喷头间最大间距（m）",
          key: "CustomMaxDisBetweenSprinklers",
          value: "3",
          val: "",
          isInput: true,
          type: "input",
          disabled: false,
          show: false,
          isReadOnly: true,
          isAlways: true
        },
        {
          label: "喷头间最小间距（m）",
          key: "CustomMinDisBetweenSprinklers",
          value: "2.4",
          val: "",
          isInput: true,
          type: "input",
          disabled: false,
          show: false,
          isReadOnly: true,
          isAlways: true
        },
        {
          label: "喷头与墙体最大间距（m）",
          key: "CustomMaxDisWithWall",
          value: "1.5",
          val: "",
          type: "input",
          isInput: true,
          disabled: false,
          show: false,
          isReadOnly: true,
          isAlways: true
        },
        {
          label: "喷头与墙体最小间距（m）",
          key: "CustomMinDisWithWall",
          value: "0.1",
          val: "",
          type: "input",
          isInput: true,
          disabled: false,
          show: false,
          isReadOnly: true,
          isAlways: true
        },
        {
          label: "喷头设置方式",
          key: "SprinklerSettingMethod",
          value: "直立型（上喷）",
          val: "",
          type: "select",
          disabled: false,
          show: true,
          isAlways: true,
          list: ["直立型（上喷）", "下垂型（下喷）", "吊顶型（上下喷）"]
        },
        {
          label: "喷淋管材",
          key: "Material",
          value: "热浸镀锌钢管",
          val: "",
          type: "select",
          disabled: false,
          show: true,
          isAlways: true,
          list: ["热浸镀锌钢管", "无缝钢管", "CPVC管", "涂覆钢管", "铜管"]
        },
        {
          label: "持续喷水时间（h）",
          key: "SprayingTime",
          key1: "SprayingTime1",
          value: "1",
          val: "",
          type: "select",
          disabled: false,
          show: true,
          isAlways: true,
          custom: true,
          list: ["1", "1.5", "2", "自定义"]
        },
        {
          label: "是否高大空间场所",
          key: "IsTallRoom",
          value: "否",
          val: "",
          type: "select",
          disabled: false,
          show: true,
          isAlways: true,
          list: ["是", "否"]
        },
        {
          label: "喷水强度( L/(min·㎡) )",
          key: "SprayStrength",
          key1: "SprayStrength1",
          value: "8",
          val: "",
          type: "select",
          disabled: false,
          show: true,
          isAlways: true,
          custom: true,
          list: [
            4,
            6,
            8,
            12,
            14,
            15,
            16,
            16.5,
            18,
            18.5,
            20,
            22,
            22.5,
            24,
            24.5,
            28.5,
            32.5,
            34.5,
            36.5,
            "自定义"
          ]
        },
        {
          label: "最不利洒水喷头工作压力（MPa）",
          key: "MinPressure",
          key1: "MinPressure1",
          value: "0.10",
          val: "",
          type: "select",
          disabled: false,
          show: true,
          isAlways: true,
          custom: true,
          list: [
            "0.05",
            "0.07",
            "0.10",
            "0.15",
            "0.20",
            "0.25",
            "0.30",
            "0.35",
            "0.40",
            "0.50",
            "0.70",
            "自定义"
          ]
        },
        {
          label: "流量系数 K",
          key: "CoefficientK",
          key1: "CoefficientK1",
          value: "80",
          val: "",
          type: "select",
          disabled: false,
          show: true,
          isAlways: true,
          custom: true,
          list: [80, 115, 161, 200, 202, 242, 320, 363, "自定义"]
        },
        {
          label: "储物类别",
          key: "StorageCategory",
          key1: "StorageCategory1",
          value: "无",
          val: "",
          type: "select",
          typeName: "仓库",
          disabled: false,
          show: false,
          isAlways: true,
          custom: true,
          list: [
            "无",
            "I级",
            "II级",
            "沥青制品",
            "箱装不发泡塑料",
            "箱装发泡塑料",
            "袋装不发泡塑料",
            "袋装发泡塑料",
            "混杂储存（储存物中包括沥青制品或箱装A组塑料橡胶）",
            "混杂储存（储存物中包括袋装A组塑料橡胶）",
            "混杂储存（储存物中包括袋装不发泡A组塑料橡胶）",
            "混杂储存（储存物中包括袋装发泡A组塑料橡胶）",
            "混杂储存（储存物中包括轮胎或卷纸）",
            "自定义"
          ]
        },
        {
          label: "储存方式",
          key: "StorageMethod",
          value: "无",
          val: "",
          type: "select",
          typeName: "仓库",
          disabled: false,
          show: false,
          isAlways: true,
          list: [
            "无",
            "堆垛",
            "托盘",
            "单排货架",
            "双排货架",
            "多排货架",
            "货架"
          ]
        },
        {
          label: "最大净空高度（m）",
          key: "MaxHeadroom",
          value: "",
          val: "",
          type: "input",
          typeName: "仓库",
          disabled: false,
          show: false,
          isAlways: true
        },
        {
          label: "最大储物高度（m）",
          key: "MaxStorageHeight",
          value: "",
          val: "",
          type: "input",
          typeName: "仓库",
          disabled: false,
          show: false,
          isAlways: true
        },
        {
          label: "是否设置货架内置喷头",
          key: "IsSetSprayInShelves",
          value: "否",
          val: "",
          type: "select",
          typeName: "仓库",
          disabled: false,
          show: false,
          isAlways: true,
          list: ["是", "否"]
        },
        {
          label: "货架内最不利喷头工作压力（MPa）",
          key: "MinPressureGz",
          key1: "MinPressureGz1",
          value: "0.20",
          val: "",
          type: "select",
          disabled: false,
          show: false,
          isAlways: true,
          custom: true,
          list: [
            "0.05",
            "0.07",
            "0.10",
            "0.15",
            "0.20",
            "0.25",
            "0.30",
            "0.35",
            "0.40",
            "0.50",
            "0.70",
            "自定义"
          ]
        },
        {
          label: "货架内置喷头流量系数K值",
          key: "ShelfCoefficientK",
          key1: "ShelfCoefficientK1",
          value: "80",
          val: "",
          type: "select",
          disabled: false,
          show: false,
          isAlways: true,
          custom: true,
          list: [80, 115, "自定义"]
        },
        {
          label: "货架内置喷头层数（层）",
          key: "ShelfFloors",
          value: "3",
          val: "",
          type: "input",
          disabled: false,
          show: false,
          isAlways: true
        },
        {
          label: "货架内开放喷头数量（个）",
          key: "NumberOfOpenSprinklers",
          value: "14",
          val: "",
          type: "input",
          disabled: false,
          show: false,
          isAlways: true
        },

        {
          label: "参与计算的图层",
          key: "selectLayers",
          value: [],
          val: "",
          type: "check",
          disabled: false,
          show: true,
          isAlways: false,
          isWareHouse: false
        },
        {
          label: "主干管与墙体距离(mm)",
          key: "distanceToWall",
          value: 400,
          val: "",
          type: "input",
          isNumber: "number",
          disabled: false,
          show: true,
          isAlways: false,
          isWareHouse: false
        }
      ];
    }
  }
};
</script>
<style scoped lang="stylus">
@import '~@/assets/styl/cad'

.block-wrapper
  height 100%
  padding 16px 0px 0px 15px !important

  .spray-item-box
    max-height 170px !important

  >>>.el-checkbox
    width calc(33.33% - 4px)
    margin-right 0
    margin-bottom 16px
    margin-right 4px
    box-sizing border-box

  >>>.el-checkbox__label
    line-height 13px
    font-size 14px
    width calc(100% - 14px)
    box-sizing border-box

    span
      display block
      text-overflow ellipsis
      overflow hidden
      white-space nowrap

.spray-select-box
  display flex

  .el-select
    flex 1

.custem-dialog
  >>>.el-dialog__body
    padding 0 20px !important
</style>
