<template>
  <el-dialog
    :append-to-body="true"
    :visible.sync="show"
    :close-on-click-modal="false"
    :modal="true"
    :modal-append-to-body="false"
    title="行业规范"
    width="1100px"
    v-dialogDrag
    :fullscreen="fullscreen"
  >
    <div class="full-box" @click="fullscreen = !fullscreen">
      <svg
        t="1680921595021"
        class="icon"
        viewBox="0 0 1024 1024"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        p-id="1669"
        width="19"
        height="19"
        v-if="!fullscreen"
      >
        <path
          d="M853.333333 0h-682.666666C75.093333 0 0 75.093333 0 170.666667v682.666666C0 948.906667 75.093333 1024 170.666667 1024h682.666666c95.573333 0 170.666667-75.093333 170.666667-170.666667v-682.666666C1024 75.093333 948.906667 0 853.333333 0zM955.733333 853.333333c0 54.613333-47.786667 102.4-102.4 102.4h-682.666666c-54.613333 0-102.4-47.786667-102.4-102.4v-682.666666C68.266667 116.053333 116.053333 68.266667 170.666667 68.266667h682.666666c54.613333 0 102.4 47.786667 102.4 102.4v682.666666z"
          p-id="1670"
        ></path>
        <path
          d="M402.773333 573.44L204.8 771.413333V648.533333c0-20.48-13.653333-34.133333-34.133333-34.133333s-34.133333 13.653333-34.133334 34.133333v204.8c0 20.48 13.653333 34.133333 34.133334 34.133334h204.8c20.48 0 34.133333-13.653333 34.133333-34.133334s-13.653333-34.133333-34.133333-34.133333H252.586667l197.973333-197.973333c13.653333-13.653333 13.653333-34.133333 0-47.786667-13.653333-13.653333-34.133333-13.653333-47.786667 0zM853.333333 136.533333h-204.8c-20.48 0-34.133333 13.653333-34.133333 34.133334s13.653333 34.133333 34.133333 34.133333h122.88L573.44 402.773333c-13.653333 13.653333-13.653333 34.133333 0 47.786667 13.653333 13.653333 34.133333 13.653333 47.786667 0L819.2 252.586667v122.88c0 20.48 13.653333 34.133333 34.133333 34.133333s34.133333-13.653333 34.133334-34.133333v-204.8c0-20.48-13.653333-34.133333-34.133334-34.133334z"
          p-id="1671"
        ></path>
      </svg>
      <svg
        v-else
        t="1680921636067"
        class="icon"
        viewBox="0 0 1024 1024"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        p-id="2302"
        width="19"
        height="19"
      >
        <path
          d="M853.333333 0h-682.666666C75.093333 0 0 75.093333 0 170.666667v682.666666C0 948.906667 75.093333 1024 170.666667 1024h682.666666c95.573333 0 170.666667-75.093333 170.666667-170.666667v-682.666666C1024 75.093333 948.906667 0 853.333333 0zM955.733333 853.333333c0 54.613333-47.786667 102.4-102.4 102.4h-682.666666c-54.613333 0-102.4-47.786667-102.4-102.4v-682.666666C68.266667 116.053333 116.053333 68.266667 170.666667 68.266667h682.666666c54.613333 0 102.4 47.786667 102.4 102.4v682.666666z"
          p-id="2303"
        ></path>
        <path
          d="M812.373333 163.84L614.4 361.813333V238.933333c0-20.48-13.653333-34.133333-34.133333-34.133333s-34.133333 13.653333-34.133334 34.133333v204.8c0 20.48 13.653333 34.133333 34.133334 34.133334h204.8c20.48 0 34.133333-13.653333 34.133333-34.133334s-13.653333-34.133333-34.133333-34.133333H662.186667l197.973333-197.973333c13.653333-13.653333 13.653333-34.133333 0-47.786667-13.653333-13.653333-34.133333-13.653333-47.786667 0zM443.733333 546.133333h-204.8c-20.48 0-34.133333 13.653333-34.133333 34.133334s13.653333 34.133333 34.133333 34.133333h122.88l-197.973333 197.973333c-13.653333 13.653333-13.653333 34.133333 0 47.786667 13.653333 13.653333 34.133333 13.653333 47.786667 0L409.6 662.186667v122.88c0 20.48 13.653333 34.133333 34.133333 34.133333s34.133333-13.653333 34.133334-34.133333v-204.8c0-20.48-13.653333-34.133333-34.133334-34.133334z"
          p-id="2304"
        ></path>
      </svg>
    </div>
    <div class="safety-container">
      <div class="operation-left">
        <div class="scroll-Y operation-left-box">
          <sub-menu
            v-if="operationList.length > 0"
            :list="operationList"
            :defaultId="defaultId"
            :openIds="openIds"
            @selectSub="selectSub"
          ></sub-menu>
          <div class="noData" v-else>暂无栏目</div>
        </div>
      </div>
      <div class="flex operation-box">
        <div class="operation-right">
          <div class="flex search-box" v-if="operationList.length > 0">
            {{ (chatData && chatData.name) || "" }}
            <div class="flex search-main">
              <el-input
                v-model="keyWord"
                prefix-icon="el-icon-search"
                placeholder="请输入文章内容搜索"
                @keyup.enter.native="onSearch"
              ></el-input>
            </div>
          </div>
          <div
            class="scroll-Y operation-main"
            v-loading="loading"
            ref="operation"
            element-loading-text="请稍等，文件加载中"
          >
            <template v-if="!loading && operationList.length > 0">
              <template
                v-if="
                  chatData && chatData.children && chatData.children.length > 0
                "
              >
                <div
                  class="operation-item"
                  v-for="(chart, idx) in chatData.children"
                  :key="idx"
                  :id="chart.id"
                >
                  <div class="title_one">
                    <div>{{ chart.chapterName || "" }}</div>
                    <div
                      class="title_one_view"
                      @click.stop="viewChart(chart.id)"
                    >
                      查看
                    </div>
                  </div>
                </div>
              </template>
              <div class="content_two" v-else>
                <template v-if="chatData">
                  <div
                    v-if="chatData && chatData.isContent"
                    v-html="chatData.content"
                  ></div>
                  <template v-if="chatData && chatData.fileType == 'pdf'">
                    <div style="position:relative;width:100%;height: 100%;">
                      <iframe
                        :src="
                          'https://dendralink.com/pdf/#/?url=' +
                            chatData.content
                        "
                      />
                    </div>
                  </template>
                  <div
                    style="height:100%;"
                    v-else
                    :ref="'content' +  chatData.id"
                  ></div>
                </template>
              </div>
            </template>
            <div class="noData" v-else>
              暂无内容
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div slot="footer" class="dialog-footer">
      <el-button @click="show = false" type="primary" size="nomal"
        >确定</el-button
      >
    </div> -->
  </el-dialog>
</template>

<script>
import subMenu from "@/components/subMenu/index";
import { get, post } from "@/api/index";
import mammoth from "mammoth";
// import pdfPreview from "@/components/pdfPreview";
export default {
  name: "safety",
  components: { subMenu },
  data() {
    return {
      fullscreen: false,
      show: false,
      keyWord: "",
      operationIndex: 0,
      selectedId: "",
      defaultId: "",
      openIds: [],
      operationList: [],
      loading: true,
      chatData: null,
      selectData: null,
      vHtml: "",
      htmlList: [],
      showDialog: false,
      showData: null,
      oldContent: null
    };
  },
  created() {},
  computed: {},
  mounted() {},
  methods: {
    onOpened() {
      this.fullscreen = false;
      this.show = true;
      this.initData();
    },
    initData() {
      this.operationList = [];
      this.openIds = [];
      post(this.$setApi("/cus/help/doc/page/standard"), {
        pageNum: 0,
        pageSize: 1000
      })
        .then(res => {
          console.log("re", res);
          this.loading = false;
          if (res.data && res.data.records && res.data.records.length > 0) {
            res.data.records.map((item, index) => {
              let data = {
                id: String(item.id),
                name: item.name,
                svgName: "czsc",
                content: item.content || "",
                contentTypeDictId: item.contentTypeDictId,
                contentType:
                  (item.contentType && item.contentType.dictLabel) || "",
                children: []
              };
              if (item.files && item.files.length) {
                data.fileList = item.files.map(ele => {
                  return {
                    id: ele.id,
                    name: ele.fileName,
                    url: ele.url,
                    address: ele.url
                  };
                });
              }
              if (index == 0) this.openIds.push(String(item.id));
              if (item.chapterList && item.chapterList.length > 0) {
                item.chapterList.map(it => {
                  if (index == 0) this.openIds.push(String(it.id));
                  data.children.push({
                    id: String(it.id),
                    name: it.chapterName,
                    content: it.content,
                    title: it.chapterName,
                    contentTypeDictId: it.contentTypeDictId,
                    fileList: []
                  });
                  if (it.files && it.files.length) {
                    data.fileList = it.files.map(e => {
                      return {
                        id: e.id,
                        name: e.fileName,
                        url: e.url,
                        address: e.url
                      };
                    });
                  }
                });
              }
              this.operationList.push(data);
            });
          }
          if (this.operationList.length > 0) {
            this.defaultId = this.operationList[0].id;
            if (this.operationList[0].children.length > 0) {
              this.defaultId = String(this.operationList[0].children[0].id);
              this.viewChart(this.operationList[0].children[0].id);
            } else this.getDetailData(this.operationList[0].id);
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getDetailData(id) {
      this.loading = true;
      this.showData = null;
      this.showDialog = false;
      this.selectedId = id;
      console.log("getDetailData");
      get(this.$setApi("/cus/help/doc/") + id).then(res => {
        this.keyWord = "";
        this.oldContent = null;
        if (res.data) {
          this.chatData = res.data;
          this.chatData.isContent = true;
          this.chatData.fileType = "";
          if (
            this.chatData.contentType &&
            this.chatData.contentType.dictLabel &&
            this.chatData.contentType.dictLabel.indexOf("上传") > -1
          ) {
            this.chatData.isContent = false;
            if (this.chatData.files && this.chatData.files.length > 0) {
              let file = this.chatData.files[0];
              this.chatData.fileType = this.$getFileType(file.fileName);
              if (this.chatData.fileType == "pdf") {
                this.chatData.content = file.url;
              } else this.chatData.isContent = true;
            }
          }
          console.log("getDetailData1");
          this.loading = false;
        }
      });
    },
    setData(data) {
      console.log("setData");
      this.chatData = JSON.parse(JSON.stringify(data));
      this.htmlList = [];
      if (this.chatData.type == "0") {
        this.chatData.children = this.chatData.chapterList.map(item => {
          item.isContent = true;
          item.fileType = "";
          if (item.contentType.dictLabel.indexOf("上传") > -1) {
            item.isContent = false;
            if (item.files && item.files.length > 0) {
              let file = item.files[0];
              item.fileType = this.$getFileType(file.fileName);
              if (item.fileType == "pdf") {
                item.content = file.url;
              } else {
                this.htmlList.push({
                  id: item.id,
                  html: ""
                });
                this.readExcelFromRemoteFile(
                  file.url,
                  item.id,
                  this.htmlList.length - 1
                );
              }
            }
          }
          return item;
        });
      } else {
        console.log("setData1");
        this.chatData.isContent = true;
        if (this.chatData.contentType.dictLabel.indexOf("上传") > -1) {
          this.chatData.content = "";
          this.chatData.isContent = false;
          if (this.chatData.files && this.chatData.files.length > 0) {
            let files = this.chatData.files[0];
            this.chatData.fileType = this.$getFileType(files.fileName);
            if (this.chatData.fileType == "pdf") {
              this.chatData.content = files.url;
              console.log("chatData1__", this.chatData.content);
            } else {
              this.htmlList.push({
                id: this.chatData.id,
                html: ""
              });
              this.readExcelFromRemoteFile(
                files.url,
                this.chatData.id,
                this.htmlList.length - 1
              );
            }
          }
        }
      }
      this.loading = false;
      if (this.htmlList.length > 0) {
        setTimeout(() => {
          this.htmlList.map(item => {
            let refHtml = this.$refs["content" + item.id];
            if (
              this.$refs["content" + item.id] &&
              this.$refs["content" + item.id].length > 0
            )
              refHtml = this.$refs["content" + item.id][0];
            refHtml.innerHTML = item.html;
          });
        }, 500);
      }
    },
    onSearch() {
      console.log("测试", this.chatData);

      if (this.chatData.content && this.chatData.fileType !== "pdf") {
        if (!this.oldContent)
          this.oldContent = JSON.parse(JSON.stringify(this.chatData.content));
        else this.chatData.content = this.oldContent;

        if (this.keyWord) {
          const reg = `/${this.keyWord}/g`;
          this.chatData.content = this.chatData.content.replace(
            eval(reg),
            "<span style='color: red;'>" + this.keyWord + "</span>"
          );
        } else {
          this.chatData.content = JSON.parse(JSON.stringify(this.oldContent));
          this.oldContent = null;
        }
      } else this.$message.warning("暂无搜索内容");
    },

    goView(id) {
      // let div = document.getElementById(id).offsetTop;
      // this.$nextTick(() => {
      //   this.$refs.operation.scrollTop = div - 52;
      // });
      // console.log("offsetTop", div, this.$refs.operation);
    },
    selectSub(key, keyPath) {
      console.log("selectSub", key, keyPath, this.selectedId);
      if (keyPath.length > 1) this.viewChart(key);
      else this.getDetailData(keyPath[0]);
      // if (this.selectedId == keyPath[0]) {
      //   if (keyPath.length > 1) this.viewChart(keyPath[1]);
      // } else {
      //   this.getDetailData(keyPath[0]);
      // }
    },
    // 在线查看word文件
    readExcelFromRemoteFile(url, id, index) {
      var vm = this;
      var xhr = new XMLHttpRequest();
      xhr.open("get", url, true);
      xhr.responseType = "arraybuffer";
      xhr.onload = function() {
        if (xhr.status == 200) {
          mammoth
            .convertToHtml({ arrayBuffer: new Uint8Array(xhr.response) })
            .then(resultObject => {
              // vm.vHtml = resultObject.value;

              vm.htmlList[index].html = resultObject.value;
            });
        }
      };
      xhr.send();
    },
    viewChart(id) {
      this.chatData = null;
      this.keyWord = "";
      this.oldContent = null;

      get(this.$setApi("/cus/help/doc/chapter/") + id).then(res => {
        console.log("viewChart0", res.data, this.chatData);

        if (res.data) {
          let item = res.data;
          this.chatData = res.data;
          this.chatData.name = item.chapterName;

          this.chatData.isContent = false;
          if (res.data.content) this.chatData.isContent = true;
          if (
            this.chatData.contentType &&
            this.chatData.contentType.dictLabel &&
            this.chatData.contentType.dictLabel.indexOf("上传") > -1
          ) {
            if (item.files && item.files.length > 0) {
              let file = item.files[0];
              item.fileType = this.$getFileType(file.fileName);
              if (item.fileType == "pdf") {
                this.chatData.isContent = false;
                this.chatData.content = file.url;
                console.log("chatData__", this.chatData.content);
              }
            }
          }
        }
      });
    }
  },
  watch: {}
};
</script>
<style lang="stylus" scoped>
.safety-container
  position: relative
  height 450px
  overflow hidden
  margin 0px auto
  border-radius 8px

  .operation-box
    position: relative
    width 100%
    height 100%
    box-sizing border-box

  .operation-left
    position: absolute
    top 0
    left 0
    width 240px
    height 100%
    margin-right 24px
    border-radius 8px
    background #fff
    z-index 100

    .zj-box
      position absolute
      top 0
      right -150px
      width 150px
      text-align center
      padding 0 16px
      background #d7d7d7
      padding-bottom 22px
      box-sizing border-box

      .zj-item
        height 29px
        margin-top 22px
        line-height 29px
        cursor pointer
        background rgba(242, 242, 242, 1)

    .operation-left-box
      overflow-x hidden
      padding 28px 10px
      height 100%
      >>>.el-menu-item
        height auto
        line-height 1.8
        span
          white-space: break-spaces
  .operation-right
    position: relative
    height 100%
    background #fff
    border-radius 8px
    margin-left 240px
    width calc(100% - 240px)

    .search-box
      justify-content space-between
      padding 0 24px
      height 52px
      align-items center
      font-size 16px
      color #222
      font-weight 600
      background #FFFFFF
      border-radius 8px 8px 0px 0px
      border 1px solid #EEEEEE

      .search-main
        align-items center
        height 46px
        width 216px

        >>>.el-input--small .el-input__inner
          height 46px
          line-height 46px
          border-radius 27px

        >>>.el-input--small .el-input__icon
          line-height 46px

    .operation-main
      height calc(100% - 52px)
      overflow-y auto
      box-sizing border-box
      text-align left
      line-height 22px
      color #222

      .operation-item
        margin-bottom 24px

        &:last-children
          margin-bottom 0

      .chat-content
        margin-top 24px

        &:last-children
          margin-top 0

      .title_one
        display: flex
        align-items: center
        justify-content: space-between
        font-size 18px
        font-weight 600
        .title_one_view
          font-size 14px
          cursor pointer
          color #999

      .title_two
        font-size 14px

      .content_two
        width 100%
        height 100%
        padding 8px 12px
        font-size 13px
        color #666
        >>>table
        >>>img
        >>>video
            width 100% !important

  .operation-txt-box
    position: absolute
    top 0
    left 0
    height 100%
    .text-back
      cursor pointer
iframe
  width 100%
  min-height 300px
  height 100%
.is-fullscreen
  .safety-container
    height 100%
  .el-dialog__body
    height calc(100% - 55px)
</style>
