<template>
  <div class="flex user-info">
    <div
      class="flex-center msg-box"
      @click="goMsg"
      v-if="userName !== '未登录用户' && isMsg"
    >
      <img alt="" :src="msgImg" />
      <div class="flex-center status-num" v-if="incomplete > 0">
        {{ incomplete > 99 ? "···" : incomplete }}
      </div>
      <div v-if="showTips && incomplete > 0" class="flex-center msg-tips-box">
        <i class="el-icon-warning"></i>
        <div class="msg-text">
          你有{{ incomplete }}条新的反馈信息，请及时处理
        </div>
        <i class="el-icon-close msg-colse" @click.stop="closeMsg"></i>
      </div>
    </div>

    <div class="flex-center logout-box" v-if="isDrown">
      <el-dropdown @command="handleCommand">
        <!-- <i class="el-icon-switch-button"></i> -->
        <div class="flex user-info-box" @click.stop="goUser">
          <div class="flex-center user-avatar">
            <img :src="userAvatar" alt="" />
          </div>
          <div class="flex-center user-name">你好, {{ userName }}</div>
          <img
            v-if="isMsg"
            src="~images/home/icon_down.png"
            class="dropdown-icon"
            alt=""
          />
        </div>
        <el-dropdown-menu slot="dropdown" v-if="isMsg">
          <el-dropdown-item
            v-for="(it, index) in list"
            :key="index"
            :command="it.url"
            >{{ it.name }}</el-dropdown-item
          >
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <el-dialog
      :append-to-body="true"
      :visible.sync="showUser"
      :close-on-click-modal="false"
      :modal="true"
      :modal-append-to-body="false"
      title="个人中心"
      width="1392px"
      v-dialogDrag
    >
      <div class="flex-center share-contnet">
        <userCenter />
        <div class="close-btn-box" @click.stop="showUser = false">
          <i class="el-icon-close"></i>
        </div>
      </div>
      <!-- <div slot="footer" class="dialog-footer">
        <el-button @click="showShare = false" type="primary" size="nomal"
          >确定</el-button
        >
      </div> -->
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import userCenter from "@/views/user/index";
export default {
  name: "userInfo",
  props: {
    isMsg: {
      type: Boolean,
      default: true
    }
  },
  components: { userCenter },
  data() {
    return {
      list: [
        {
          name: "个人中心",
          url: "userCenter"
        },
        {
          name: "帮助中心",
          url: "operation"
        },
        {
          name: "消息中心",
          url: "message"
        },
        {
          name: "修改密码",
          url: "changePwd"
        },
        {
          name: "退出登录",
          url: "signOut"
        }
      ],
      showUser: false,
      msgNum: 10,
      msgImg: require("images/home/icon_tz.png"),
      avatar: require("images/avatar.png"),
      isDrown: true,
      timer: null
    };
  },
  created() {},
  computed: {
    ...mapGetters(["userInfo", "showTips", "incomplete"]),
    userName() {
      return this.$getUserName(this.userInfo ? this.userInfo.nickName : "");
    },
    userAvatar() {
      return this.userInfo && this.userInfo.avatarUrl
        ? this.userInfo.avatarUrl
        : this.avatar;
    }
  },
  mounted() {},
  activated() {
    this.isDrown = true;
    if (this.$getToken) {
      if (!this.timer) {
        this.timer = setInterval(() => {
          this.$store.dispatch("user/getInCompleteNum").catch(() => {
            clearInterval(this.timer);
            this.timer = null;
          });
        }, 5000);
      }
    }
  },
  deactivated() {
    this.isDrown = false;
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  },
  methods: {
    closeMsg() {
      this.$store.dispatch("user/setShowTip");
    },
    goHome() {
      this.$router.replace("/");
    },
    // 操作方法
    handleCommand(command) {
      // if (command == "userCenter" && !this.isMsg) {
      //   this.showUser = true;
      //   return;
      // }
      if (command === this.routerName || !command) return;
      if (command == "changePwd")
        this.$router.push({
          name: "forgetPwd"
        });
      else if (command == "signOut") {
        setTimeout(() => {
          this.$store.dispatch("user/loginOut");
        }, 200);
      } else this.$router.replace(command);
    },
    goMsg() {
      this.$router.replace("message");
    },
    // 跳转个人中心
    goUser() {
      if (this.isMsg) this.$router.replace("userCenter");
      else this.showUser = true;
    },
    // 跳转页面
    goPath(item) {
      if (item.url === this.routerName) return;
      this.$router.replace(item.url);
    }
  },
  watch: {
    showTips: {
      handler(newVal) {
        if (newVal) {
          setTimeout(() => {
            this.$store.dispatch("user/setShowTip");
          }, 10000);
        }
      },
      immediate: true
    }
  }
};
</script>
<style lang="stylus" scoped>
.close-btn-box
  position: fixed
  top 10%
  right 14.5%
  z-index 1001
  color #000
  font-size 32px
  font-weight 700
  cursor pointer
.user-info
    position: absolute
    top 0
    right 16px
    height 100%
    font-size 16px
    align-items: center
    .msg-box
       position relative
       margin-right 16px
       height 100%
       cursor pointer
       img
        width 26px
        height 26px
    .msg-tips-box
      position: absolute
      bottom -30px
      right -15px
      z-index 100
      padding 8px 2px
      border-radius 5px
      font-size: 14px
      min-width 300px
      color: #3478f7
      background: #e2ecfe
      &:before
        position: absolute
        top -12px
        content ""
        display block
        width 0
        height 0
        right 20px
        border 6px solid
        border-color transparent transparent #f5f8ff transparent

      i
        font-size 18px
      .msg-colse
        cursor pointer
      .msg-text
        margin 0 8px
        line-height: 1.5
    .status-num
      position absolute
      top 10px
      right -5px
      width 20px
      height 20px
      border-radius 50%
      color #fff
      z-index 10
      font-size 12px
      background #ec808d
    .user-info-box
      height 100%
      cursor pointer
      align-items center
      color #fff
      &:hover
        .dropdown-icon
          transform: rotate(-180deg)
      .dropdown-icon
        width 18px
        height 18px

    .logout-box
      i
        font-size 28px
        cursor pointer
    .user-name
      margin-left: 8px
      margin-right: 12px
      font-size 18px
    .user-avatar
      width 40px
      height 40px
      display flex
      align-items center
      border-radius 50%
      img
        width 100%
        height: 100%
        border-radius 50%
    >>>.el-dropdown
        display: flex
        align-items: center
</style>
