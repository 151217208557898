<template>
  <div class="color-input-box">
    <div
      class="attar-icon icon-select"
      :style="{ background: judegBylayer(colorValue, false) }"
    ></div>
    <el-select
      v-model="colorValue"
      placeholder=""
      @change="checkColor"
      :ref="'refColor' + refName"
      @visible-change="val => visbleChageColor(val)"
    >
      <el-option
        v-for="(it, idx) in colorList"
        :key="idx"
        :label="it.name"
        :value="it.color"
      >
        <div class="flex color-list-item">
          <div
            class="attar-icon"
            :style="{ background: judegBylayer(it.index) }"
          ></div>
          <div class="attar-name">{{ it.name }}</div>
        </div>
      </el-option>
    </el-select>
    <!-- 颜色选择 -->
    <el-dialog
      title="颜色选择"
      :visible.sync="showTypeColor"
      width="732px"
      :append-to-body="true"
      :close-on-click-modal="false"
      @close="cancelColor"
      class="custem-dialog"
      v-dialogDrag
      v-disable-contextmenu
    >
      <div class="cad-select_wrapper" v-stopdrag>
        <div class="block-wrapper">
          <div class="color-wrapper">
            <div class="color-item-box">
              <div
                class="color-item"
                v-for="item in colorLists"
                :key="item.index"
                :style="{ background: Rgb2Hex(item.color) }"
                @click="selectColor(item, item.index)"
                :class="{ 'color-item-active': colorIndex == item.index }"
              >
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="String(item.index)"
                  placement="top"
                >
                  <div>
                    <div
                      class="active-box"
                      v-show="colorIndex == item.index"
                    ></div>
                    <!-- <i
                      v-show="colorIndex == item.index"
                      class="el-icon-check color-active"
                    ></i> -->
                  </div>
                </el-tooltip>
              </div>
            </div>
            <div class="flex color-btn-box">
              常用颜色：
              <div
                class="color-common-item"
                v-for="item in useColors"
                :key="item.index"
                :style="{ background: Rgb2Hex(item.color) }"
                :class="{ 'color-item-active': colorIndex == item.index }"
                @click="selectColor(item, item.index)"
              >
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="String(item.index)"
                  placement="top"
                >
                  <div>
                    <div
                      class="active-box"
                      v-show="colorIndex == item.index"
                    ></div>
                  </div>
                </el-tooltip>
              </div>
            </div>
            <div class="flex color-btn-box" style="margin-top:12px;">
              <div
                class="color-common-item"
                v-for="item in useColorss"
                :key="item.index"
                :style="{ background: Rgb2Hex(item.color) }"
                :class="{ 'color-item-active': colorIndex == item.index }"
                @click="selectColor(item, item.index)"
              >
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="String(item.index)"
                  placement="top"
                >
                  <div>
                    <div
                      class="active-box"
                      v-show="colorIndex == item.index"
                    ></div>
                  </div>
                </el-tooltip>
              </div>
            </div>
          </div>
        </div>
        <div class="color-select-box">
          <div class="search-color">
            <div class="color-label">颜色(下标)：</div>
            <el-input
              placeholder="请输入颜色(下标)"
              v-model="colorIndex"
              v-stopdrag
              @blur="searchColor"
              @change="searchColor"
              @keyup.enter.native.stop="searchColor"
            >
            </el-input>
          </div>
          <div
            v-if="colorVal"
            class="show-color-box"
            :style="{ background: colorVal }"
          >
            <div
              class="show-color-border"
              :style="{ background: colorVal }"
            ></div>
            <div class="show-color" :style="{ background: colorVal }"></div>
          </div>
        </div>
      </div>
      <div slot="footer" class="flex-center block-footer">
        <el-button @click="cancelColor" size="nomal" native-type="button"
          >取消</el-button
        >
        <el-button @click="sureColorVal" type="primary" size="nomal" v-stopdrag
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: [Number, String],
      default: -1
    },
    color: {
      type: String,
      default: "#fff"
    },
    refName: {
      type: String,
      default: "colors"
    }
  },
  mixins: [],
  computed: {
    colorLists() {
      return window.colorLists;
    },
    colorList() {
      return window.colors;
    },
    useColors() {
      return window.colorLists.slice(1, 10);
    },
    useColorss() {
      return window.colorLists.slice(
        window.colorLists.length - 5,
        window.colorLists.length
      );
    }
  },
  data() {
    return {
      bylayerColor: "",
      colorValue: "",
      isOther: false,
      colorVal: "", //颜色值
      colorIndex: "", //颜色下标
      showTypeColor: false,
      oldColorIndex: -1,
      oldColor: "",
      MathIndex: Math.random(0, 100)
    };
  },
  mounted() {
    this.$nextTick(() => {});
  },
  methods: {
    returnColorListsData(color) {
      let colorInfo = {
        index: -1,
        color: "bylayer"
      };
      window.colorLists.some(item => {
        if (item.color == color.replace(/#/gi, "0x")) {
          colorInfo = item;
          return true;
        }
      });
      return colorInfo;
    },
    returnColorIndex(index) {
      let colorInfo = {
        index: -1,
        color: "bylayer"
      };
      window.colorLists.some(item => {
        if (item.index == index) {
          colorInfo = item;
          return true;
        }
      });
      return colorInfo;
    },
    judegBylayer(colorValues, isIndex = true) {
      if (isIndex) {
        let colorValue = Number(colorValues) || -2;
        if (colorValue >= -1 && colorValue <= 255) {
          if (colorValue == -1)
            return this.bylayerColor ? this.bylayerColor : "#fff";
          else {
            let coloActive = "";

            window.colorLists.some(item => {
              if (item.index == colorValue) {
                coloActive = item.color;
                return true;
              }
            });
            console.log("judegBylayer", colorValue, coloActive);
            return this.$rgbTenToSix(coloActive);
          }
        } else if (colorValue == 300)
          return "linear-gradient(to right,#5f1b00,#eaea14,#001fdf,#00a13e)";
        else return "#fff";
      } else {
        let moreColor = this.bylayerColor || "#fff";
        return colorValues.indexOf("bylayer") > -1
          ? moreColor.replace(/0X/gi, "#")
          : colorValues.replace(/0X/gi, "#");
      }
    },
    cancelColor() {
      this.colorValue = JSON.parse(JSON.stringify(this.oldColor));
      this.showTypeColor = false;
    },
    checkColor(val) {
      if (val != "linear-gradient(to right,#5f1b00,#eaea14,#001fdf,#00a13e)") {
        let dataColor = this.returnColorListsData(val);
        this.oldColorIndex = JSON.parse(JSON.stringify(dataColor.index));
        this.oldColor = JSON.parse(JSON.stringify(this.colorValue));
        this.$emit("callBackColor", {
          sname: "color",
          index: dataColor.index,
          val: dataColor.color.replace(/0X/gi, "#"),
          value: dataColor.color,
          isOther: false,
          aci: true
        });
      }
    },
    visbleChageColor(val) {
      if (!val) {
        this.selectOtherColor(val);
        console.log("visbleChageColor", val, this.colorValue);
      }
    },
    // 选择其他颜色
    selectOtherColor() {
      this.isOther = false;
      if (
        this.colorValue ==
        "linear-gradient(to right,#5f1b00,#eaea14,#001fdf,#00a13e)"
      ) {
        this.isOther = true;
        this.colorIndex = JSON.parse(JSON.stringify(this.oldColorIndex));
        this.colorVal = JSON.parse(JSON.stringify(this.oldColor));

        this.showTypeColor = true;
      }
    },
    // 选择颜色
    selectColor(item, index = "") {
      this.colorVal = item && item.color ? this.Rgb2Hex(item.color) : "";
      this.colorIndex = index == "" ? "" : Number(index);
    },
    // 颜色确定
    sureColorVal() {
      if (!this.colorVal) this.$message.warning("不是有效的颜色名或值.");
      this.selectColorData();
    },
    selectColorData() {
      let dataColor = {
        sname: "color",
        index: this.colorIndex,
        val: this.colorVal,
        value: this.colorVal.replace(/#/gi, "0X"),
        isOther: this.isOther
      };
      this.oldColorIndex = JSON.parse(JSON.stringify(this.colorIndex));
      this.oldColor = JSON.parse(JSON.stringify(this.colorVal));
      this.$emit("callBackColor", dataColor);
      this.showTypeColor = false;
    },
    // 颜色搜索
    searchColor() {
      let num = this.colorIndex.replace(/[^\-?\d]/g, "");
      if (num == "") {
        this.colorVal = "";
        this.$message.warning("不是有效的颜色名或值.");
      } else {
        num = Number(this.colorIndex.replace(/[^\-?\d]/g, ""));
        if (num >= 0 && num <= this.colorLists.length - 1) {
          this.colorVal = this.Rgb2Hex(this.colorLists[num].color);
          this.colorValue = JSON.parse(JSON.stringify(this.num));
        } else {
          this.colorVal = "";
          this.$message.warning("不是有效的颜色名或值.");
        }
      }
    },
    Rgb2Hex(color) {
      let hex = color.replace(/0x/gi, "#");
      return hex;
    },
    setOldData(data) {
      this.oldColorIndex = JSON.parse(JSON.stringify(data.index));
      this.oldColor = JSON.parse(JSON.stringify(data.color));
    }
  },
  watch: {
    value: {
      handler(newVal) {
        this.bylayerColor = "";
        if (newVal == -1) {
          this.colorValue = "bylayer";
          this.bylayerColor = this.color;
          this.setOldData({
            color: "bylayer",
            index: -1
          });
        } else {
          let dataInfo = this.returnColorIndex(newVal);
          this.colorValue = dataInfo.color.replace(/0X/gi, "#");
          this.setOldData(dataInfo);
        }
        console.log("测试", newVal, this.colorValue, this.bylayerColor);
      },
      immediate: true
    }
  }
};
</script>
<style scoped lang="stylus">
.color-input-box
  position relative
  width 100%
  height 100%

  .icon-select
    position absolute
    top 50%
    left 15px
    z-index 110
    transform translateY(-50%)

  >>>.el-select
    width 100%
    height 100%
    line-height auto

  >>>.el-input__inner
    padding-left 32px !important

.color-list-item
  position relative
  padding 0 10px
  height 34px
  display flex
  align-items center
  cursor pointerf

.attar-icon
  background #ffffff
  border 1px solid #000
  width 14px
  height 14px
  border-radius 2px
  margin-right 10px

// 颜色框样式
.color-item-box
  display flex
  flex-wrap wrap
  padding-right 8px

  .color-item
    position relative
    width 16px
    height 16px
    margin-left 3px
    margin-bottom 3px

    div
      position relative
      width 100%
      height 100%
      border 1px solid #000
      border-radius 2px
      box-sizing border-box
      cursor pointer

.color-btn-box
  margin-top 32px
  flex-wrap wrap
  padding-right 8px

  .color-common-item
    position relative
    width 16px
    height 16px
    margin-left 3px
    margin-bottom 3px

    div
      position relative
      width 100%
      height 100%
      border 1px solid #000
      border-radius 2px
      box-sizing border-box
      cursor pointer

.color-active
  position absolute
  top 50%
  left 50%
  z-index 100
  color red
  font-weight 700
  font-size 36px
  transform translate(-50%, -50%)

.color-select-box
  margin 32px 32px 0 0
  display flex
  align-items center
  justify-content space-between

  .color-label
    margin-bottom 5px

  >>>.el-input
    flex 0 0 80% !important
    width 80% !important

  >>>.el-input__inner
    border none
    border-radius 0
    border-bottom 1px solid #000

  .show-color-box
    position relative
    width 50px
    height 50px
    padding 8px
    box-sizing border-box
    border 1px solid #b5b7bc

    .show-color-border
      position absolute
      width 21px
      height 21px
      top 0px
      right 0px
      z-index 3

    .show-color
      position absolute
      width 40px
      height 40px
      top -20px
      right -20px
      padding 8px
      box-sizing border-box
      border 1px solid #b5b7bc
      z-index 1

.color-item-active
  position relative

  .active-box
    position absolute
    top -3px
    left -3px
    width 20px !important
    height 20px !important
    border 1px solid red !important

  div
    border-color transparent !important
</style>
