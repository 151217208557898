<template>
  <div>
    <el-dialog
      :append-to-body="true"
      :close-on-click-modal="false"
      :visible.sync="showYlt"
      :modal="true"
      :modal-append-to-body="false"
      title="喷淋系统图"
      width="600px"
      style="text-align:left"
      v-dialogDrag
      v-disable-contextmenu
      @close="closeDialogs"
    >
      <div style="text-align:center" class="form-wrapper" v-closeSelect>
        <div class="tk-list-item">
          <el-radio-group v-model="yltData.type">
            <el-radio border label="pl">普通喷淋系统图</el-radio>
            <el-radio border label="ck">货架喷淋系统图</el-radio>
          </el-radio-group>
        </div>
        <div class="tk-list-item">
          <div style="width:120px">图框列表：</div>
          <el-select
            v-model="yltData.tkVal"
            style="margin-right: 10px;margin-bottom: 0px;width:100%;"
            collapse-tags
            ref="refYlt"
            placeholder="请选择自用图框列表"
          >
            <el-option
              v-for="item in blockList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </div>
        <div class="tk-list-item flex-end">
          <el-button @click="addNewTk">新增图框 </el-button>
        </div>
        <div
          class="tk-list-item"
          :style="{
            'justify-content':
              yltData.type !== 'ck' ? 'flex-start' : 'space-between'
          }"
        >
          <div class="tk-input">
            <div class="tk-span">绘制比例：</div>
            <el-input v-model="yltData.hzbl" class="form-input" type="number" />
          </div>
          <div class="tk-input" v-if="yltData.type == 'ck'">
            <div class="tk-span">绘制层数：</div>
            <el-input v-model="yltData.hzcs" class="form-input" type="number" />
          </div>
        </div>
        <div class="tk-list-item" v-if="yltData.type !== 'ck'">
          <div class="tk-input" style="width:70%;">
            <el-button @click.stop="selectData" type="primary" size="small"
              >选择数据</el-button
            >
            <div class="data-tab-box">
              <el-table :data="box_list" style="width: 100%;" height="100%">
                <el-table-column
                  prop="index"
                  align="center"
                  label="序号"
                  width="150"
                >
                </el-table-column>

                <el-table-column
                  prop="height"
                  align="center"
                  label="层高"
                  width="300"
                >
                  <template slot-scope="scope">
                    <el-input
                      v-model="scope.row.height"
                      class="form-input input-center"
                      type="number"
                    >
                      <template slot="append">m</template>
                    </el-input>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="onDoSubmitYl" type="primary" size="nomal"
            >确定</el-button
          >
          <el-button @click="closeDialogs()" size="nomal" native-type="button"
            >取消</el-button
          >
        </div>
      </div>
    </el-dialog>
    <el-dialog
      :append-to-body="true"
      :close-on-click-modal="false"
      :visible.sync="showLibrary"
      :modal="true"
      :modal-append-to-body="false"
      title="设计库"
      width="620px"
      style="text-align:left"
      v-dialogDrag
    >
      <div class="cad-dialog-wrapper">
        <div class="flex cad-dialog-search">
          <el-input
            placeholder="请输入设计库名称"
            v-model="libraryName"
            @keyup.enter.native="searchLibrary"
          >
            <i slot="prefix" class="el-input__icon el-icon-search"></i>
          </el-input>
          <el-button class="commom-button" type="primary" @click="searchLibrary"
            >搜索</el-button
          >
          <el-button class="commom-button" type="primary" @click="onShowLibrary"
            >新增</el-button
          >
        </div>
        <div class="scroll-Y cad-dialog-container">
          <div class="flex cad-dialog-item-box">
            <div class="scroll-Y cad-dialog-container">
              <div v-if="loadingLibrary && libraryList.length > 0">
                <div
                  class="cad-dialog-box"
                  v-for="(it, index) in libraryList"
                  :key="index"
                >
                  <div class="cad-dialog-title">{{ it.typeName || "" }}</div>
                  <div
                    class="flex cad-dialog-item-box"
                    v-if="it.dataList && it.dataList.length > 0"
                  >
                    <template>
                      <div
                        class="flex-center cad-dialog-item"
                        v-for="(item, index) in it.dataList"
                        :key="index"
                      >
                        <div
                          class="cad-dialog-close"
                          @click.stop="delLibrary(item, index)"
                          v-if="item.canDel"
                        >
                          <img src="~images/home/icon_close.png" alt="" />
                        </div>
                        <div class="cad-dialog-top">
                          <img :src="getImgUrl(item.cover)" alt="" />
                        </div>
                        <div class="text_hidden cad-dialog-name">
                          {{ item.name }}
                        </div>
                      </div>
                    </template>
                  </div>
                  <div class="cad-dialog-item-box" v-else>
                    <div class="noData">暂无数据</div>
                  </div>
                </div>
              </div>

              <div class="flex cad-dialog-item-box" v-else>
                <div class="noData">
                  {{ !loadingLibrary ? "加载中..." : "暂无数据" }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <el-dialog
        width="30%"
        title="新增设计库"
        :visible.sync="innerVisible"
        :close-on-click-modal="false"
        append-to-body
        v-dialogDrag
      >
        <div style="text-align:center" class="form-wrapper">
          <el-form
            ref="libraryForm"
            :model="problemData"
            :rules="libraryRules"
            size="small"
            label-width="auto"
          >
            <el-form-item prop="typeDictId" label="类型">
              <el-select
                v-model="problemData.typeDictId"
                style="margin-right: 10px;margin-bottom: 0px;width:100%;"
                collapse-tags
                ref="refYlt2"
                placeholder="请选择类型"
              >
                <el-option
                  v-for="item in typeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="文件" prop="snapshot">
              <!-- <updateFile v-model="problemData.fileList"></updateFile> -->
              <el-upload
                ref="uploadmap"
                :auto-upload="false"
                :show-file-list="false"
                :on-change="changeLibrary"
                class="avatar-uploader"
                action=""
                accept=".dwg"
              >
                <img
                  v-if="problemData.snapshot"
                  :src="problemData.snapshot"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon addImg" />
              </el-upload>
            </el-form-item>
            <el-form-item label="设计库名称" prop="name">
              <el-input v-model="problemData.name" class="form-input" />
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="onDoSubmit" type="primary" size="nomal"
              >确定</el-button
            >
            <el-button
              @click="cancelDialog('innerVisible')"
              size="nomal"
              native-type="button"
              >取消</el-button
            >
          </div>
        </div>
      </el-dialog>
    </el-dialog>
  </div>
</template>

<script>
import { get, post, del } from "@/api/index";
import { mapGetters } from "vuex";
import { CommandFactory, CommandStatus } from "@/cadplugins/map/commandfactory";
import axios from "axios";
import pako from "pako";
import { getToken } from "@/utils/auth";
import { DrawUtilImpl } from "@/cadplugins/tools/drawutils";
import { RBox, LTPoint } from "@/cadplugins/cad/ltpoint";
import { LTUtils } from "@/cadplugins/cad/ltutils";
import { LTText } from "@/cadplugins/cad/ltentity";
import { dialogMixin } from "@/utils/dialogMixin";
export default {
  mixins: [dialogMixin],
  props: {
    shareUrl: {
      type: String,
      default: ""
    },
    supThis: {
      type: Object
    }
  },
  name: "schematicdiagram",
  components: {},
  data() {
    const validFile = (rule, value, callback) => {
      if (!value || value.length == 0) {
        callback(new Error("请选择文件"));
      } else {
        callback();
      }
    };

    return {
      imgraw: null,
      snapshot: require("images/home/work-img.png"),
      //原理图
      showYlt: false,
      yltType: "自用", //判断设计库类型
      blockList: [],
      yltData: {
        type: "pl",
        tkVal: "",
        hzbl: 100,
        hzcs: "" //绘制层数
      },
      yltRules: {},
      // 设计库
      libraryIndex: -1,
      loadingLibrary: false,
      showLibrary: false,
      libraryName: "",
      libraryFile: null,
      libraryList: [],
      innerVisible: false,
      problemData: {
        id: "",
        name: "",
        cover: "",
        typeDictId: "",
        fileList: []
      },
      typeList: [],
      loading: false,
      libraryRules: {
        name: [
          { required: true, message: "请输入设计库名称", trigger: "blur" }
        ],
        typeDictId: [
          { required: true, message: "请输入设计库类型", trigger: "blur" }
        ],
        snapshot: [{ required: true, trigger: "blur", message: "请上传文件" }], //validator: validFile
        cover: [{ required: true, message: "请上传缩略图", trigger: "blur" }]
      },
      box_list: []
    };
  },
  created() {
    this.getData();
    // this.init();
  },
  computed: {
    ...mapGetters(["userInfo"])
  },
  mounted() {},

  methods: {
    // 唤起原理图
    init(map, project) {
      let data = {
        pageNum: 0,
        pageSize: 999,
        param: {}
      };
      this.libraryList = [];
      this.blockList = [];

      this.blockList.push({
        id: -10,
        name: "A0(1189x841)",
        json: JSON.stringify(this.standradBlock("A0(1189x841)", 1189, 841))
      });
      this.blockList.push({
        id: -11,
        name: "A1(841x594)",
        json: JSON.stringify(this.standradBlock("A1(841x594)", 841, 594))
      });
      this.blockList.push({
        id: -12,
        name: "A2(594x420)",
        json: JSON.stringify(this.standradBlock("A2(594x420)", 594, 420))
      });
      this.blockList.push({
        id: -13,
        name: "A3(420x297)",
        json: JSON.stringify(this.standradBlock("A3(420x297)", 420, 297))
      });
      this.blockList.push({
        id: -14,
        name: "A4(297x210)",
        json: JSON.stringify(this.standradBlock("A4(297x210)", 297, 210))
      });

      get(this.$setApi("/cus/design-lib/type/list"), data)
        .then(res => {
          if (res && res.data && res.data.length == 0) {
            return;
          }
          for (let item of res.data) {
            if (item.typeName.indexOf(this.yltType) == -1) {
              continue;
            }

            this.libraryList.push({
              ...item,
              id: item.id,
              name: item.name,
              cover: item.cover
            });
            if (item.dataList && item.dataList.length > 0) {
              item.dataList.map(sub_sub_data => {
                this.blockList.push({
                  id: sub_sub_data.id,
                  name: sub_sub_data.name,
                  filename: sub_sub_data.files
                });
              });
            }
          }
          this.showYlt = true;
        })
        .catch(() => {
          this.loadingLibrary = true;
        });
    },
    // 选择数据
    selectData() {
      this.supThis.pixiMap.clearTrackGraphics();
      this.showYlt = false;
      CommandFactory.getInstance().execCommandFun(
        "select1",
        this.select1,
        this.supThis.pixiMap,
        null
      );
    },
    addNewTk() {
      // this.showYlt = false
      this.onShowSjk();
    },

    select1: function*(map, params) {
      //正常情况下为查找选择某个图层下的数据;
      let index = 0;
      this.box_list = [];
      while (true) {
        CommandFactory.getInstance().getPoint(map, "选择数据范围的起点:");
        let startPoint = yield;
        if (
          startPoint.done == CommandStatus.eCancel ||
          startPoint.done == CommandStatus.eDone
        ) {
          break;
        }
        let query_box = null;
        function drawRectCallback(mouse) {
          let query_box2 = RBox.formVectors(startPoint.value, mouse.mapPos);
          if (query_box != null) {
            DrawUtilImpl.destroyEntity(map, query_box);
          }
          query_box = LTUtils.boxToPolyline(query_box2);
          DrawUtilImpl.trackEntity(map, query_box);
        }
        CommandFactory.getInstance().getPoint(
          map,
          "选择数据范围的终点:",
          drawRectCallback
        );
        let endPoint = yield;
        if (
          endPoint.done == CommandStatus.eCancel ||
          endPoint.done == CommandStatus.eDone
        ) {
          break;
        }
        let bbox = new RBox.formVectors(startPoint.value, endPoint.value);
        index += 1;

        let pline = LTUtils.boxToPolyline(bbox);
        pline.setColor("#FF0000");
        DrawUtilImpl.trackEntity(map, pline);

        let text = LTUtils.drawCenterText(
          index.toString(),
          bbox.getHeight() / 2,
          bbox
        );
        text.setColor("#FF0000");
        text.subData().fontName = "汉仪中等线";
        DrawUtilImpl.trackEntity(this.supThis.pixiMap, text);

        this.box_list.push({ index: index, box: bbox, height: 0 });
      }
      this.showYlt = true;

      return this.box_list;
    },
    closeDialogs() {
      this.box_list = [];
      this.showYlt = false;
      this.supThis.pixiMap.clearTrackGraphics();
      this.closeDialog();
    },
    // 原理图确定
    onDoSubmitYl() {
      this.supThis.pixiMap.clearTrackGraphics();

      this.box_list.sort((a, b) => a.height - b.height);

      let box_list_filter = this.box_list.filter(
        (item, index, self) =>
          index === self.findIndex(i => i.height === item.height)
      );

      if (this.yltData.tkVal.length == 0) {
        this.$message.warning("请选择图框");
        return;
      }
      if (this.yltData.type == "ck" && this.yltData.hzcs.length == 0) {
        this.$message.warning("绘制层数不能为0");
        return;
      }
      this.showYlt = false;
      this.addDesign(this.yltData.tkVal).then(res => {
        let use_house = false;
        if (this.yltData.type == "ck") {
          use_house = true;
        }
        let param = {
          type: use_house,
          blockName: res,
          blockScale: this.yltData.hzbl,
          floorNum: this.yltData.hzcs,
          box_list: box_list_filter
        };
        this.showYlt = false;

        CommandFactory.getInstance().execYieldCommand(
          "sparydesign",
          this.supThis.pixiMap,
          param
        );
      });
    },
    standradBlock(blockname, width, height) {
      let cad_geo = {
        close: false,
        points: [
          {
            bulges: 0.0,
            endwidth: 0.0,
            startwidth: 0.0,
            x: 0.0,
            y: 0.0
          },
          {
            bulges: 0.0,
            endwidth: 0.0,
            startwidth: 0.0,
            x: width,
            y: 0.0
          },
          {
            bulges: 0.0,
            endwidth: 0.0,
            startwidth: 0.0,
            x: width,
            y: height
          },
          {
            bulges: 0.0,
            endwidth: 0.0,
            startwidth: 0.0,
            x: 0.0,
            y: height
          },
          {
            bulges: 0.0,
            endwidth: 0.0,
            startwidth: 0.0,
            x: 0,
            y: 0.0
          }
        ]
      };
      let standrad_block_json = {
        block: {
          "0": {
            cadgeo: JSON.stringify(cad_geo),
            entitytype: "acdbpolyline",
            linetype: "ByLayer",
            entitylayer: "0",
            color: '{"aci":false,"color":"bylayer","index":-1}',
            linetype: "ByLayer",
            info: '{"linetypesize":1.0}',
            update: true,
            xdata: "null"
          },
          blockname: blockname,
          geometrycount: 1
        }
      };
      return standrad_block_json;
    },
    getData() {
      // 类型
      post(this.$setApi("/sys/dict/data/page?pageNum=0&pageSize=100"), {
        param: {
          dictType: "design-lib-type"
        }
      }).then(res => {
        this.typeList = [];
        if (res.data && res.data.records && res.data.records.length > 0) {
          res.data.records.map(item => {
            if (item.dictLabel.indexOf(this.yltType) > -1) {
              this.typeList.push({
                value: item.id,
                label: item.dictLabel
              });
            }
          });
        }
      });
    },
    getImgUrl(pic) {
      if (pic) return pic;
      else return this.snapshot;
    },
    // 唤起设计库
    onShowSjk() {
      this.initBrary();
      this.showLibrary = true;
    },
    // 搜索设计库
    searchLibrary() {
      this.initBrary();
    },
    initBrary() {
      this.loadingLibrary = false;
      let data = {
        pageNum: 0,
        pageSize: 999,
        param: {}
      };
      if (this.libraryName) data.name = this.libraryName;
      this.libraryList = [];
      this.blockList = [];
      get(this.$setApi("/cus/design-lib/type/list"), data)
        .then(res => {
          this.loadingLibrary = true;
          if (res && res.data && res.data.length > 0) {
            res.data.some(item => {
              if (
                item &&
                item.typeName &&
                item.typeName.indexOf(this.yltType) > -1
              )
                this.libraryList.push({
                  ...item,
                  id: item.id,
                  name: item.name,
                  cover: item.cover
                });
              return true;
            });
          }
        })
        .catch(() => {
          this.loadingLibrary = true;
        });
    },
    onShowLibrary() {
      this.innerVisible = true;
      this.addProblem = true;
    },
    handleMapChange(file) {
      this.$set(this.problemData, "cover", URL.createObjectURL(file.raw));
      this.fileraw = file.raw;
    },
    changeLibrary(file) {
      let flieArr = file.name.split(".");
      let suffix = flieArr[flieArr.length - 1];
      if (suffix == "dwg") {
        this.problemData.fileraw = file.raw;
        const lastIndexOfPoint = file.raw.name.lastIndexOf(".");
        const suffix_ = file.raw.name.substring(0, lastIndexOfPoint);
        this.problemData.name = suffix_;
        this.$set(
          this.problemData,
          "snapshot",
          require("@/assets/images/fileIcon/dwg.png")
        );
      } else {
        this.$message.warning("请选择dwg格式文件!!!");
      }
    },
    onDoSubmit() {
      this.$validateForm((valid, mess) => {
        if (valid) {
          var postData = new FormData();
          postData.append("id", this.problemData.id);
          postData.append("name", this.problemData.name);
          postData.append("typeDictId", this.problemData.typeDictId);
          if (this.problemData.fileraw)
            postData.append("files", this.problemData.fileraw);
          if (this.fileraw) postData.append("avatar", this.fileraw);
          if (this.addProblem) {
            post(this.$setApi("/cus/design-lib"), postData)
              .then(res => {
                if (res) {
                  this.innerVisible = false;
                  this.$message.success("新增成功");
                  this.resetForm();
                  this.initBrary();
                }
              })
              .catch(err => {
                console.log("失败", err);
                // this.$message.warning(err);
              });
          } else {
            post(this.$setApi("/cus/design-lib/update"), postData)
              .then(res => {
                if (res.data) {
                  this.dialogProblem = false;
                  this.$message.success("编辑成功");
                  this.resetForm();
                  this.init();
                }
              })
              .catch(err => {
                console.log("失败", err);
                // this.$message.warning(err);
              });
          }
        } else {
          this.$message.warning(mess[0].message);
        }
      }, "libraryForm");
    },
    // 删除设计库
    delLibrary(it) {
      this.$confirm("是否删除设计库, 是否继续?", "提示", {
        confirmButtonText定: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          // this.libraryList.splice(idx, 1);
          if (!it.id) return;
          del(this.$setApi("/cus/design-lib/") + it.id).then(res => {
            if (res) {
              this.$message.success("删除成功");
              this.initBrary();
            }
          });
        })
        .catch(() => {});
    },

    addDesign(id) {
      return new Promise((resolve, reject) => {
        let sub_item = this.blockList.find((item, item_index) => {
          if (item.id == id) {
            return true;
          }
          return false;
        });
        if (sub_item == null) {
          return reject("");
        }
        if (this.supThis.pixiMap.dwgservice.hasBlock(sub_item.name)) {
          resolve(sub_item.name);
          return;
        }
        if (sub_item.json === undefined) {
          axios({
            url:
              window.configData.VUE_APP_BASEURL +
              "/cus/design-lib/block/" +
              sub_item.id, // 请求地址
            method: "get",
            headers: {
              Authorization: "Bearer " + getToken()
            },
            responseType: "arraybuffer" // 表明返回服务器返回的数据类型
          })
            .then(res => {
              let str = pako.inflate(res.data, { to: "string" });
              let json = JSON.parse(str);
              //返回图框名;
              json.block.blockname = sub_item.name;
              this.supThis.pixiMap.dwgservice.addDesign(json);
              resolve(sub_item.name);
            })
            .catch(err => {
              const buffer = Buffer.from(err.response.data, "utf-8");
              let err_json = JSON.parse(buffer.toString());
              this.$message.warning(err_json.message);
              reject("");
            });
        } else {
          let json = JSON.parse(sub_item.json);
          //返回图框名;
          this.supThis.pixiMap.dwgservice.addDesign(json);
          resolve(sub_item.name);
        }
      });
    }
  }
};
</script>
<style lang="stylus" scoped>
@import '~@/assets/styl/sjk';
.data-tab-box
  margin-left 15px
  max-height 280px

>>>.input-center .el-input__inner
  text-align: center
.tk-list-item
  display flex
  justify-content flex-start
  align-items center
  width 100%
  margin-bottom:20px
  &>>>.el-select
      margin-right 0 !important
  &>>>.el-radio-group
      display flex
      width 100%
      justify-content space-between
  .tk-input
    width 100%
    display flex
    align-items center
    .tk-span
      width 120px
.flex-end
  justify-content flex-end

.cad-item-box
  .el-button
    &:first-child
      margin-left 8px

.cad-dialog-search
  margin-bottom 24px
  justify-content flex-end

  >>>.el-input--small
    width 50%
    margin-right 16px

  >>>.el-input--small .el-input__inner
    height 40px
    line-height 40px
</style>
