<template>
  <div>
    <!-- 自定义命令-->
    <el-dialog
      title="自定义命令"
      :visible.sync="showDialog"
      width="680px"
      :append-to-body="true"
      :close-on-click-modal="false"
      @close="showDialog = false"
      class="custem-dialog"
      v-dialogDrag
      v-disable-contextmenu
    >
      <div class="dialog-body-box">
        <div
          class="cad-select_wrapper scroll-Y"
          v-stopdrag
          style="height: 405px;"
        >
          <div class="block-wrapper">
            <el-table :data="tableData" style="width: 100%;" height="100%">
              <el-table-column prop="command_name" label="命令名">
                <template slot-scope="scope">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="getText(scope.row.command_name)"
                    placement="top-start"
                  >
                    <span>{{ scope.row.command_name }}</span>
                  </el-tooltip>
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                prop="simple_command_name"
                label="简命令"
              >
                <template slot-scope="scope">
                  <el-input
                    v-model="scope.row.simple_command_name"
                    @blur="
                      () =>
                        blurSimpleInput(
                          scope.row.simple_command_name,
                          scope.$index
                        )
                    "
                  />
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                prop="keyword_command_name"
                label="快捷命令"
              >
                <template slot-scope="scope">
                  <el-input
                    v-model="scope.row.keyword_command_name"
                    @focus="
                      () => changeInput(scope.row, 'keyword_command_name')
                    "
                    @blur="
                      () =>
                        blurInput(scope.row.keyword_command_name, scope.$index)
                    "
                    clearable
                    @clear="() => clearVal(scope.row, 'keyword_command_name')"
                  />
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
      <div slot="footer" class="flex-center block-footer">
        <el-button @click="showDialog = false" size="nomal" native-type="button"
          >取消</el-button
        >
        <el-button @click="sureDialog" type="primary" size="nomal"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { CommandFactory } from "@/cadplugins/map/commandfactory";
export default {
  data() {
    return {
      showDialog: false,
      tableData: [
        // {
        //   shortcut: "快捷",
        //   use: "通用",
        //   custom: "自定义"
        // }
      ],
      keyArrs: []
    };
  },
  mounted() {
    this.$nextTick(() => {});
  },
  methods: {
    onShowDialog(data = []) {
      this.tableData = data || [];
      this.showDialog = true;
    },
    sureDialog() {
      //保存;
      window.saveUserSettingData(
        "customcommand",
        JSON.stringify(this.tableData)
      );

      this.showDialog = false;
    },
    changeInput(row, type = "") {
      this.keyArrs = [];
      this.keyCodeForEvent(row, type);
    },
    clearVal(row, type) {
      row[type] = "";
    },
    //模拟组合键触发函数
    keyCodeForEvent(row, type) {
      let self = this;
      document.onkeydown = function(e) {
        let evn = e || event;
        evn.preventDefault();

        let key = evn.key;
        let keyTxt = evn.key;
        if (keyTxt == "Backspace") {
          row[type] = "";
          self.keyArrs = [];
          return;
        }
        switch (key) {
          case "Control":
            keyTxt = "Ctrl";
            break;
        }
        self.keyArrs.push(keyTxt);
        let lastKey = self.keyArrs[self.keyArrs.length - 1],
          lastTwo = "";
        if (self.keyArrs.length > 1)
          lastTwo = self.keyArrs[self.keyArrs.length - 2];
        if (self.returnVal(lastKey)) {
          row[type] = "";
        } else {
          if (self.keyArrs.length == 1) row[type] = self.keyArrs[0];
          else if (self.returnVal(lastTwo)) {
            row[type] = `${lastTwo}+${lastKey}`;
          } else row[type] = `${lastKey}`;
          self.keyArrs = [];
        }
        console.log(
          "onkeydown",
          evn.key,
          lastTwo,
          lastKey,
          self.returnVal(lastTwo),
          self.keyArrs
        );
        console.log("onkeydown1", row[type]);
      };
    },
    blurSimpleInput(val, i) {
      if (val) {
        let index = -1;
        this.tableData.some((item, idx) => {
          if (item.simple_command_name.toLowerCase() == val.toLowerCase()) {
            index = idx;
            return true;
          }
        });

        if (index > -1 && i != index) {
          this.$message.warning("简命令冲突!!!");
          this.tableData[i].simple_command_name = "";
        }
      }
    },
    getText(command_name) {
      let command = CommandFactory.getInstance().getCommand(command_name);
      if (command == null) {
        return command_name;
      }
      return command.commandTip;
    },
    blurInput(val, i) {
      if (!val) {
        document.onkeydown = null;
        return;
      }
      this.tableData[i].keyword_command_name = this.tableData[
        i
      ].keyword_command_name.toUpperCase();
      let index = -1;
      this.tableData.some((item, idx) => {
        if (item.keyword_command_name.toLowerCase() == val.toLowerCase()) {
          index = idx;
          return true;
        }
      });
      if (index > -1 && i != index) {
        this.$message.warning("快捷键冲突!!!");
        this.tableData[i].keyword_command_name = "";
      }
      document.onkeydown = null;
    },
    returnVal(val) {
      return (
        val.indexOf("Ctrl") > -1 ||
        val.indexOf("Shift") > -1 ||
        val.indexOf("Alt") > -1
      );
    }
  }
};
</script>
<style scoped lang="stylus">
@import '~@/assets/styl/cad'

.line-container
  max-height 540px
  height 540px

  .block-wrapper
    height 100%
</style>
